import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { AppSettingsService } from "@services/appSettings.service";
import { AuthService } from "@services/auth.service";
import { LoadingService } from "@services/loading.service";
import { environment } from "@environments/environment";
import { profile } from "console";

@Injectable()
export class HttpClientInterceptor implements HttpInterceptor {
	refreshTokenRetryTimeout: any = undefined;

	constructor(private appSettingsService: AppSettingsService, private authService: AuthService, private loadingService: LoadingService) { }

	intercept(
		request: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		const accessToken = this.appSettingsService.get("accessToken", "");
		// Needed for every call except the login call
		const authorizedRequest = request.clone({
			url: `${request.url}?token=${accessToken}`
		});

		if (request.url.includes(environment.server.storageUrl)) return next.handle(request);
		switch (request.url) {
			case `${environment.server.apiURL}/Auth/CreateAdminToken`:
				this.loadingService.emit(true);
				return next.handle(request).pipe(
					tap((evt) => {
						if (evt instanceof HttpResponse) {
							if (evt != null) {
								this.loadingService.emit(false);
								this.appSettingsService.set("accessToken", evt.body.response.accessToken);
								this.appSettingsService.set("accessTokenExpiryTimeMilliseconds", new Date().getTime() + environment.server.accessTokenExpiryMilliseconds);
								this.authService.isAuthed = true;
								this.authService.refreshAccessToken();
							}
						}
					}),
					catchError((error) => {
						const errorMsg = error.error.response.error;

						// TODO: Refactor to fancy alerts
						// dialogs.alert({
						//     message: errorMsg,
						//     okButtonText: "Ok",
						//     title: "An error occurred!"
						// });

						this.loadingService.emit(false);

						return throwError(errorMsg);
					})
				);
			case `${environment.server.apiURL}/StaffAttendance/FobScanned/`:
				{
					const clonedRequest = request.clone({ headers: request.headers.append("Authorization", "5dbsvGCsXrynN62Kd7T5BrEJWoYOmWIY") });
					return next.handle(clonedRequest);
				}
			case `${environment.server.apiURL}/Auth/CreateToken`:
				this.loadingService.emit(true);
				return next.handle(request).pipe(
					tap((evt) => {
						if (evt instanceof HttpResponse) {
							if (evt != null) {
								this.loadingService.emit(false);
								this.authService.isAuthed = true;
								this.authService.refreshAccessToken();
							}
						}
					}),
					catchError((error) => {
						const errorMsg = error.error.response.error;

						// TODO: Refactor to fancy alerts
						// dialogs.alert({
						//     message: errorMsg,
						//     okButtonText: "Ok",
						//     title: "An error occurred!"
						// });

						this.loadingService.emit(false);

						return throwError(errorMsg);
					})
				);
			case `${environment.server.apiURL}/Auth/RefreshToken`:
				return next.handle(authorizedRequest).pipe(
					catchError((error) => {
						const errorMsg = error.error.response.error;

						this.authService.clearRefreshInterval();

						if (errorMsg === "Invalid Token Supplied.") {
							// TODO: Refactor to logout
							// dialogs
							//     .alert({
							//         message:
							//             "You have been logged out of the app.",
							//         okButtonText: "Ok",
							//         title: "Logged out!"
							//     })
							//     .then(() => this.router.navigate([""]));
						} else {
							this.refreshTokenRetryTimeout = setTimeout(() => {
								this.authService
									.refreshToken()
									.subscribe((res: any) => {
										this.appSettingsService.set(
											"accessToken",
											res.response.accessToken
										);
										this.appSettingsService.set(
											"accessTokenExpiryTimeMilliseconds",
											new Date().getTime() + environment.server.accessTokenExpiryMilliseconds
										);
										this.authService.refreshAccessToken();
									});
							}, 15000); // Set to refresh every 15 seconds if the previous RefreshToken call fails
						}

						return throwError(errorMsg);
					})
				);
			default:

				if (request.url.includes("Machine/OpsPlannerByInterfaceDevice")) {
					const clonedRequest = request.clone({
						headers: request.headers.append("Authorization", "5dbsvGCsXrynN62Kd7T5BrEJWoYOmWIY")
					});
					return next.handle(clonedRequest);
				}
				if (request.url.includes("IncreaseCompletedQuantity") || request.url.includes("UpdateProductionStatus") || request.url.includes("RequestSupervisorForStage")) {
					const profileId = this.appSettingsService.get("profileId");
					console.log(profileId);
					const clonedRequest = request.clone({
						headers: request.headers.append("Authorization", "5dbsvGCsXrynN62Kd7T5BrEJWoYOmWIY").append("profileId",profileId.toString())
					});
					
					return next.handle(clonedRequest);
				}

				this.loadingService.emit(true);
				return next.handle(authorizedRequest).pipe(
					tap((evt) => {
						if (evt instanceof HttpResponse) {
							if (evt != null) {
								this.loadingService.emit(false);
							}
						}
					}),
					catchError((error) => {
						const errorMsg = error.error.response.error;
						const status = error.error.status;
						const validationErrors = error.error.response.validationErrors;

						if (status === 401) this.authService.isAuthed = false;

						// TODO: Refactor to fancy alerts
						// dialogs.alert({
						//     message: errorMsg,
						//     okButtonText: "Ok",
						//     title: "An error occurred!"
						// });

						this.loadingService.emit(false);

						if (validationErrors) return throwError(validationErrors);
						else return throwError(errorMsg);
					})
				);
		}
	}
}