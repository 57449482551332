<admin-header [primaryButtons]="headerPrimaryButtons" [title]="title" (eventFired)="headerButtonClick($event)"></admin-header>
<dx-data-grid class="data-grid" [dataSource]="items" [remoteOperations]="false" [allowColumnReordering]="true" [allowColumnResizing]="true" [rowAlternationEnabled]="true" [showBorders]="true" (onExporting)="logExport()">
    <dxo-paging [pageSize]="100"></dxo-paging>
	<dxo-state-storing [enabled]="true" type="localStorage" storageKey="workforceMain"></dxo-state-storing>
    <dxo-pager [visible]="true" [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
    <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"></dxo-search-panel>
    <dxo-group-panel [visible]="true"></dxo-group-panel>
    <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
	<dxo-export [enabled]="true"></dxo-export>
	<dxo-scrolling columnRenderingMode="virtual"></dxo-scrolling>
    <dxi-column dataField="firstName" dataType="string" alignment="left"></dxi-column>
    <dxi-column dataField="lastName" dataType="string" alignment="left"></dxi-column>
    <dxi-column dataField="jobTitle" dataType="string" alignment="left"></dxi-column>
	<dxi-column dataField="shiftId" dataType="string" alignment="left" caption="Shift"><dxo-lookup [dataSource]="shifts" valueExpr="id" displayExpr="name"></dxo-lookup></dxi-column>
	<dxi-column dataField="siteId" dataType="string" alignment="left" caption="Site"><dxo-lookup [dataSource]="sites" valueExpr="id" displayExpr="name"></dxo-lookup></dxi-column>
	<dxi-column dataField="defaultDepartment" dataType="string" alignment="left" caption="Default Dept."><dxo-lookup [dataSource]="departments" valueExpr="id" displayExpr="name"></dxo-lookup></dxi-column>
	<dxi-column dataField="currentDepartment" dataType="string" alignment="left" caption="Current Dept."><dxo-lookup [dataSource]="departments" valueExpr="id" displayExpr="name"></dxo-lookup></dxi-column>
	<dxi-column type="buttons">
        <dxi-button hint="Reset Current Department" icon="fal fa-undo" [onClick]="resetCurrentDepartment"></dxi-button>
        <dxi-button hint="Change Current Department" icon="fal fa-user-cog" [onClick]="changeCurrentDepartmentPopup"></dxi-button>
    </dxi-column>
</dx-data-grid>
<dx-popup [width]="750" [height]="600" [showTitle]="true" [title]="popupTitle" [dragEnabled]="false" [closeOnOutsideClick]="false" [showCloseButton]="false" container=".dx-viewport" [(visible)]="popupVisible">
	<dxi-toolbar-item widget="dxButton" toolbar="bottom" location="before" [options]="popupAddButtonOptions"></dxi-toolbar-item>
	<dxi-toolbar-item widget="dxButton" toolbar="bottom" location="after" [options]="popupCloseButtonOptions"></dxi-toolbar-item>
	<div *ngIf="popupTitle === 'Change Current Department'">
		<dx-form class="m-3" [(formData)]="popupChangeUserProfileDepartment" [showColonAfterLabel]="true" labelLocation="top">
			<dxi-item dataField="department" editorType="dxSelectBox" [editorOptions]="{ items: dropdownDepartments, searchEnabled: true, displayExpr:'name', valueExpr:'id' }" [label]="{ text: 'Department' }"><dxi-validation-rule type="required" message="Department is required"></dxi-validation-rule></dxi-item>
		</dx-form>
	</div>
</dx-popup>