import { Injectable, Injector } from "@angular/core";
import { environment } from "@environments/environment";
import { Service } from "@services/service";

@Injectable()
export class WorkOrderService extends Service {
	constructor(injector: Injector) {
		super("WorkOrder", injector);
	}

	// API	
	complete(id: number){
		return this.http.post(`${environment.server.apiURL}/${this.endpoint}/Complete/${id}`, {});
	}
	
	generateAndDownloadMasterLabelPdf(id: number) {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/GenerateAndDownloadMasterLabelPdf/${id}`, { responseType: "blob" as "json"} );
	}

	generateAndDownloadPdf(id: number) {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/GenerateAndDownloadPdf/${id}`, { responseType: "blob" as "json"} );
	}

	getAllOrders(){
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/GetAll`);
	}

	getForOriginal(id: number){
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/ForOriginal/${id}`);
	}

	getForParent(id: number){
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/ForParent/${id}`);
	}

	getForSalesOrder(id: number){
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/ForSalesOrder/${id}`);
	}

	getStageSummaryForPart(partId: number){
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/StageSummaryForPart/${partId}`);
	}
	
	// todo: change this to have parameters when they have been defined
	opsPlanningWorkOrderSummaryPdf() {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/OpsPlanningWorkOrderSummaryPdf`, { responseType: "blob" as "json"} );
	}
	
	split(id: number){
		return this.http.post(`${environment.server.apiURL}/${this.endpoint}/Split/${id}`, {});
	}
}