import { Injectable, Injector } from "@angular/core";
import { environment } from "@environments/environment";
import { Service } from "@services/service";

@Injectable()
export class InvoiceService extends Service {
	constructor(injector: Injector) {
		super("Invoice", injector);
	}

	// API
	generateAndPreviewPdf(id: number) {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/GenerateAndPreviewPdf/${id}`, { responseType: "blob" as "json"} );
	}

	getBySalesOrderId(id: number) {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/GetBySalesOrderId/${id}`);
	}

	getGrid() {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/GetGrid`);
	}

	getGridForInvoice(id: number) {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/GetLineItemsGridForInvoice/${id}`);
	}

	markCompleted(id: number) {
		return this.http.post(`${environment.server.apiURL}/${this.endpoint}/MarkCompleted/${id}`, {});
	}

	markSent(id: number) {
		return this.http.post(`${environment.server.apiURL}/${this.endpoint}/MarkSent/${id}`, {});
	}

	updateLineItems(id: number, data: any) {
		return this.http.patch(`${environment.server.apiURL}/${this.endpoint}/UpdateLineItems/${id}`, data);
	}

	updateNotes(id: number, data: any) {
		return this.http.patch(`${environment.server.apiURL}/${this.endpoint}/UpdateNotes/${id}`, data);
	}
}