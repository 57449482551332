import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { Department } from "@enums/department";
import { Shift } from "@enums/shift.enum";
import { GetPropertiesFromEnum } from "@helpers/getPropertiesFromEnum";
import { AdminHeaderButton } from "@interfaces/adminHeaderButton.interface";
import { ChangeUserProfileDepartment } from "@interfaces/changeUserProfileDepartment.interface";
import { LogExport } from "@interfaces/logExport.interface";
import { Site } from "@interfaces/site.interface";
import { UserProfile } from "@interfaces/userProfile.interface";
import { ExportLogService } from "@services/exportLog.service";
import { SiteService } from "@services/site.service";
import { UserProfileService } from "@services/userProfile.service";
import notify from "devextreme/ui/notify";

@Component({
	moduleId: module.id,
	selector: "workforce",
	styleUrls: ["workforce.css"],
	templateUrl: "workforce.html"
})
export class OpsPlanningWorkforceAdminComponent {
	departments: any;
	dropdownDepartments: any;
	headerPrimaryButtons: AdminHeaderButton[] = [];
	items: UserProfile[] = [];
	popupAddButtonOptions: any;
	popupChangeUserProfileDepartment: ChangeUserProfileDepartment = new ChangeUserProfileDepartment();
	popupCloseButtonOptions: any = { onClick: () => this.closePopup(), text: "Close" };
	popupTitle = "Change Current Department";
	popupVisible = false;
	shifts: any;
	sites: Site[] = [];
	title: string;
	
	constructor(private router: Router, private exportLogService: ExportLogService, private itemService: UserProfileService, private siteService: SiteService) {
		this.departments = GetPropertiesFromEnum(Department);
		this.shifts = GetPropertiesFromEnum(Shift);
		this.title = "Workforce";
		this.changeCurrentDepartmentPopup = this.changeCurrentDepartmentPopup.bind(this);
		this.resetCurrentDepartment = this.resetCurrentDepartment.bind(this);
		this.getItems();
		this.getSites();

		// set allowed departments for the change department dropdown
		this.dropdownDepartments = [];

		const filteredDepartments = this.departments.filter((d: { id: number; name: string; }) => d.id > 0 && d.id < 60);
		filteredDepartments.forEach((fd: any) => {
			this.dropdownDepartments.push(fd);
		});

		this.buildButtons();
	}

	buildButtons() {
		this.headerPrimaryButtons = [
			{ method: "goToWorkforce", text: "Workforce" }
		];

		this.dropdownDepartments.forEach((fd: any) => {
			this.headerPrimaryButtons.push({ method: "goToDepartment-" + fd.id, text: fd.name });
		});
	}

	changeCurrentDepartment() {
		this.itemService.changeCurrentDepartment(this.popupChangeUserProfileDepartment)
			.subscribe(
				() => {
					notify("Current Department Updated", "success", 5000);
					this.getItems();
					this.closePopup();
				},
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				},
			);
	}

	changeCurrentDepartmentPopup(e: any) {
		this.popupChangeUserProfileDepartment = new ChangeUserProfileDepartment();
		this.popupChangeUserProfileDepartment.userProfileId = e.row.data.id;
		this.popupChangeUserProfileDepartment.department = e.row.data.currentDepartment;
		this.popupAddButtonOptions = { onClick: () => this.changeCurrentDepartment(), text: "Change Department" };
		this.popupTitle = "Change Current Department";
		this.popupVisible = true;
	}

	closePopup() {
		this.popupVisible = false;
	}

	getItems() {
		this.itemService.getWorkforce()
			.subscribe(
				(res: any) => this.items = res.response,
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				},
			);
	}

	getSites() {
		this.siteService.getAll()
			.subscribe(
				(res: any) => this.sites = res.response.items,
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				},
			);
	}

	goToDepartment(department: string) {
		this.router.navigate([`/admin/opsPlanning/department/${department}`]);
	}

	goToWorkforce() {
		this.router.navigate(["/admin/opsPlanning/workforce"]);
	}

	headerButtonClick(method: string) {
		if (method.indexOf("-") > 0) {
			const split = method.split("-");
			// @ts-ignore // Required to be able to call the method directly from the variable
			this[split[0]](split[1]);
		}
		else
		{
			// @ts-ignore // Required to be able to call the method directly from the variable
			if (this[method]) this[method]();
		}
	}

	logExport() {
		const logExport = new LogExport();
		logExport.gridName = "Workforce";
		this.exportLogService.logExport(logExport)
			.subscribe(
				() => console.log("Export Logged"),
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
					return false;
				},
			);
	}

	resetCurrentDepartment(e: any) {
		const result = confirm("Are you sure you want to reset the current department of this user?");
		if(result)
		{
			this.itemService.resetCurrentDepartment(e.row.data.id)
				.subscribe(
					() => {
						notify("Current Department Reset", "success", 5000);
						this.getItems();
					},
					(err) => {
						console.log(err);
						notify("Something went wrong!", "error", 5000);
					},
				);
		}
	}
}