import { Injectable, Injector } from "@angular/core";
import { environment } from "@environments/environment";
import { FobScanned } from "@interfaces/fobScanned.interface";
import { Service } from "@services/service";

@Injectable()
export class StaffAttendanceService extends Service {
	constructor(injector: Injector) {
		super("StaffAttendance", injector);
	}

	// API
	fobScanned(fobScanned: FobScanned) {
		return this.http.post(`${environment.server.apiURL}/${this.endpoint}/FobScanned/`,fobScanned);
	}

	forSite(id: number) {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/ForSite/${id}`);
	}

	forUserProfile(id: number) {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/ForUserProfile/${id}`);
	}

	openSessionForUserProfile(id: number) {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/OpenSessionForUserProfile/${id}`);
	}
}