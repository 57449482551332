import { AfterViewInit, Component, ViewEncapsulation } from "@angular/core";
import { ProductionStatus } from "@enums/productionStatus.enum";
import { AdminHeaderButton } from "@interfaces/adminHeaderButton.interface";
import { Machine } from "@interfaces/machine.interface";
import { Site } from "@interfaces/site.interface";
import { MachineService } from "@services/machine.service";
import { SiteService } from "@services/site.service";
import Konva from "konva";

@Component({
	encapsulation: ViewEncapsulation.None,
	moduleId: module.id,
	selector: "opfloorplan",
	styleUrls: ["opfloorplan.css"],
	templateUrl: "opfloorplan.html"
})
export class OpFloorplanComponent implements AfterViewInit {
	floorPlanHeight = 900;
	floorPlanWidth = 1000;
	headerPrimaryButtons: AdminHeaderButton[] = [];
	machineLayer: Konva.Layer = new Konva.Layer();
	machines: Machine[] = [];
	popupCloseButtonOptions: any = { onClick: () => this.closePopup(), text: "Close" };
	popupMachine: any = null;
	popupTitle = "Machine Info";
	popupVisible = false;
	selectedSiteId = 1;
	sites: Site[] = [];
	stage?: Konva.Stage;
	title = "Operation Floorplan";

	constructor(private machineService: MachineService, private siteService: SiteService) {
		this.getSites();
	}

	addMachine(machine: Machine) {
		const group = new Konva.Group({
			height: machine.depth,
			name: machine.id.toString(),
			rotation: machine.rotationAmount,
			width: machine.width,
			x: machine.xPosition,
			y: machine.yPosition
		});

		const shape = new Konva.Rect({
			cornerRadius: 5,
			fill: this.getStatusColour(machine.productionStatus),
			height: machine.depth,
			shadowBlur: 5,
			width: machine.width
		});
		group.add(shape);

		const text = new Konva.Text({
			align: "center",
			fill: "black",
			fontFamily: "Calibri",
			fontSize: 18,
			height: machine.depth,
			text: machine.cellNumber + "\n" + machine.name,
			verticalAlign: "middle",
			width: machine.width
		});
		group.add(text);

		this.machineLayer.add(group);
	}

	changeSite(siteId: number) {
		this.selectedSiteId = siteId;

		const selectedSite = this.sites.filter((d: Site) => d.id == siteId);
		this.title = "Operation Floorplan - " + selectedSite[0].name;
		this.floorPlanHeight = selectedSite[0].floorPlanHeight;
		this.floorPlanWidth = selectedSite[0].floorPlanWidth;

		this.machineService.getBySite(siteId)
			.subscribe(
				(res: any) => {
					this.machines = res.response;
					this.renderPlacedMachines();
				},
				(err) => console.log(err)
			);
	}

	closePopup() {
		this.popupVisible = false;
	}

	getMachines(siteId: number) {
		this.machineService.getBySite(siteId)
			.subscribe(
				(res: any) => {
					this.machines = res.response;
					this.renderPlacedMachines();
				},
				(err) => console.log(err)
			);
	}

	getSites() {
		this.siteService.getAll()
			.subscribe(
				(res: any) => {
					this.sites = res.response.items;
					if (this.sites.length > 0) {
						this.sites.sort((a,b) => a.name.localeCompare(b.name));
						this.selectedSiteId = this.sites[0].id;
						this.getMachines(this.sites[0].id);
						
						this.title = "Operation Floorplan - " + this.sites[0].name;
						this.floorPlanHeight = this.sites[0].floorPlanHeight;
						this.floorPlanWidth = this.sites[0].floorPlanWidth;
						this.headerPrimaryButtons = [];
						this.sites.forEach((site: any) => {
							this.headerPrimaryButtons.push({ method: "changeSite-" + site.id, text: site.name });
						});
					}
				},
				(err) => console.log(err)
			);
	}

	getStatusColour(status: ProductionStatus) {
		switch(status) {
			case ProductionStatus.Active:
				return "green";
			case ProductionStatus.Idle:
				return "yellow";
			case ProductionStatus.Maintenance:
				return "red";
			default:
				return "blue";
		}
	}

	headerButtonClick(method: string) {
		if (method.indexOf("-") > 0) {
			const split = method.split("-");
			// @ts-ignore // Required to be able to call the method directly from the variable
			this[split[0]](split[1]);
		}
		else
		{
			// @ts-ignore // Required to be able to call the method directly from the variable
			if (this[method]) this[method]();
		}
	}

	initCanvas() {
		const divName = "opfloorplan";
		const canvas = document.getElementById(divName);
		if (canvas) {
			this.stage = new Konva.Stage({
				container: divName,
				height: this.floorPlanHeight,
				width: this.floorPlanWidth
			});
			this.stage.add(this.machineLayer);

			this.stage.on("click tap", (e) => {
				if (e.target instanceof Konva.Group) {
					this.showInfoModal(e.target);
					return;
				}
				if (e.target.parent instanceof Konva.Group) {
					this.showInfoModal(e.target.parent);
					return;
				}
			});

			this.machineLayer.removeChildren();
		}
	}

	ngAfterViewInit() {
		this.initCanvas();
	}

	renderPlacedMachines() {
		this.initCanvas();
		this.machines.filter((machine) => machine.xPosition !== -1 || machine.yPosition !== -1).forEach((machine) => this.addMachine(machine));
	}

	showInfoModal(item: Konva.Group) {
		this.machineService.floorPlanMachineSummary(Number(item.name()))
			.subscribe(
				(res: any) => {
					this.popupMachine = res.response;
					this.popupVisible = true;
				},
				(err) => console.log(err)
			);
	}
}