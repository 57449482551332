import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { FormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { RouterModule } from "@angular/router";

import { EmailEditorModule } from "angular-email-editor";
import { DxBarGaugeModule, DxButtonGroupModule, DxButtonModule, DxChartModule, DxColorBoxModule, DxContextMenuModule, DxDataGridModule, DxDrawerModule, DxDropDownBoxModule, DxDropDownButtonModule, DxFileUploaderModule, DxFormModule, DxHtmlEditorModule, DxListModule, DxPieChartModule, DxPopupModule, DxSchedulerModule, DxSelectBoxModule, DxSpeedDialActionModule, DxTabsModule, DxTextAreaModule, DxTextBoxModule, DxToastModule } from "devextreme-angular";
import { NgxStripeModule } from "ngx-stripe";

import { environment } from "@environments/environment";
import { HttpClientInterceptor } from "@interceptors/httpInterceptor";

import { AppComponent } from "./app.component";
import { components, routes } from "./app.routing";

import { AccidentService } from "@services/accident.service";
import { AppSettingsService } from "@services/appSettings.service";
import { AuditService } from "@services/audit.service";
import { AzureBlobStorageService } from "@services/azureBlobStorage.service";
import { AuthService } from "@services/auth.service";
import { BankDetailsService } from "@services/bankDetails.service";
import { BannerService } from "@services/banner.service";
import { ClientService } from "@services/client.service";
import { ClientContactService } from "@services/clientContact.service";
import { ClientDocumentService } from "@services/clientDocument.service";
import { ClientLocationService } from "@services/clientLocation.service";
import { CompanyHolidayService } from "@services/companyHoliday.service";
import { DashboardService } from "@services/dashboard.service";
import { DeliveryNoteService } from "@services/deliveryNote.service";
import { EdiForecastService } from "@services/ediForecast.service";
import { EdiMessageService } from "@services/ediMessage.service";
import { EdiOrderService } from "@services/ediOrder.service";
import { EdiShippingNoteService } from "@services/ediShippingNote.service";
import { EdiShippingNoteItemService } from "@services/ediShippingNoteItem.service";
import { EmailService } from "@services/email.service";
import { EmailTemplateService } from "@services/emailTemplate.service";
import { ExportLogService } from "@services/exportLog.service";
import { FeatureService } from "@services/feature.service";
import { FileService } from "@services/file.service";
import { GroupService } from "@services/group.service";
import { HolidayAllowanceService } from "@services/holidayAllowance.service";
import { HolidayRequestService } from "@services/holidayRequest.service";
import { HolidaySettingsService } from "@services/holidaySettings.service";
import { InterfaceDeviceService } from "@services/interfaceDevice.service";
import { InvoiceService } from "@services/invoice.service";
import { LoadingService } from "@services/loading.service";
import { LogService } from "@services/logs.service";
import { MachineService } from "@services/machine.service";
import { MachineEventService } from "@services/machineEvent.service";
import { MaterialService } from "@services/material.service";
import { MenuService } from "@services/menu.service";
import { MenuItemService } from "@services/menuItem.service";
import { ModalService } from "@services/modal.service";
import { NonConformanceReportService } from "@services/nonConformanceReport.service";
import { OpsActionAuditService } from "@services/opsActionAudit.service";
import { OutsourcedSpecificationService } from "@services/outsourcedSpecification.service";
import { PackagingService } from "@services/packaging.service";
import { PartService } from "@services/part.service";
import { PartRelationshipService } from "@services/partRelationship.service";
import { PFMEAService } from "@services/pfmea.service";
import { ProcessService } from "@services/processService";
import { ProcessMachineService } from "@services/processMachine.service";
import { ProductionRequestService } from "@services/productionRequestService";
import { ProjectService } from "@services/project.service";
import { PromiseService } from "@services/promise.service";
import { PurchasableItemService } from "@services/purchasableItem.service";
import { PurchaseLogService } from "@services/purchaseLog.service";
import { QmsDocumentService } from "@services/qmsDocument.service";
import { QmsDocumentVersionService } from "@services/qmsDocumentVersion.service";
import { RequestForQuoteService } from "@services/requestForQuote.service";
import { SalesEnquiryService } from "@services/salesEnquiry.service";
import { SalesOrderService } from "@services/salesOrder.service";
import { SalesOrderPartService } from "@services/salesOrderPart.service";
import { SiteService } from "@services/site.service";
import { SmsService } from "@services/sms.service";
import { SmsTemplateService } from "@services/smsTemplate.service";
import { StaffAttendanceService } from "@services/staffAttendance.service";
import { StockStoreService } from "@services/stockStore.service";
import { StockStoreHistoryService } from "@services/stockStoreHistory.service";
import { SupplierService } from "@services/supplier.service";
import { SupplierContactService } from "@services/supplierContact.service";
import { SupplierDocumentService } from "@services/supplierDocument.service";
import { SupplierLocationService } from "@services/supplierLocation.service";
import { SupplierPurchaseTypeService } from "@services/supplierPurchaseType.service";
import { SystemParameterService } from "@services/systemParameter.service";
import { ToolService } from "@services/tool.service";
import { ToolEventService } from "@services/toolEvent.service";
import { TrainingService } from "@services/trainingService.service";
import { UserService } from "@services/user.service";
import { UserProfileService } from "@services/userProfile.service";
import { VisitService } from "@services/visit.service";
import { VisitorService } from "@services/visitor.service";
import { WorkOrderService } from "@services/workOrder.service";
import { WorkOrderStageService } from "@services/workOrderStage.service";
import { WorkOrderStageInspectionService } from "@services/workOrderStageInspection.service";

@NgModule({
	bootstrap: [AppComponent],
	declarations: [
		AppComponent,
		...components
	],
	imports: [
		BrowserModule,
		CommonModule,
		EmailEditorModule,
		HttpClientModule,
		FormsModule,
		RouterModule,
		RouterModule.forRoot(routes),
		DxBarGaugeModule,
		DxButtonModule,
		DxButtonGroupModule,
		DxChartModule,
		DxColorBoxModule,
		DxContextMenuModule,
		DxDataGridModule,
		DxDrawerModule,
		DxDropDownBoxModule,
		DxDropDownButtonModule,
		DxFileUploaderModule,
		DxFormModule,
		DxHtmlEditorModule,
		DxListModule,
		DxPieChartModule,
		DxPopupModule,
		DxSchedulerModule,
		DxSelectBoxModule,
		DxSpeedDialActionModule,
		DxTabsModule,
		DxTextBoxModule,
		DxTextAreaModule,
		DxToastModule,
		NgxStripeModule.forRoot(environment.server.stripeKey)
	],
	providers: [
		AccidentService,
		AppSettingsService,
		AuditService,
		AzureBlobStorageService,
		AuthService,
		BankDetailsService,
		BannerService,
		ClientContactService,
		ClientDocumentService,
		ClientLocationService,
		ClientService,
		CompanyHolidayService,
		DashboardService,
		DeliveryNoteService,
		EdiForecastService,
		EdiMessageService,
		EdiOrderService,
		EdiShippingNoteService,
		EdiShippingNoteItemService,
		EmailService,
		EmailTemplateService,
		ExportLogService,
		FeatureService,
		FileService,
		GroupService,
		HolidayAllowanceService,
		HolidayRequestService,
		HolidaySettingsService,
		InterfaceDeviceService,
		InvoiceService,
		LoadingService,
		LogService,
		MachineService,
		MachineEventService,
		MaterialService,
		MenuService,
		MenuItemService,
		ModalService,
		NonConformanceReportService,
		OpsActionAuditService,
		OutsourcedSpecificationService,
		PackagingService,
		PartService,
		PartRelationshipService,
		PFMEAService,
		ProcessService,
		ProcessMachineService,
		ProductionRequestService,
		ProjectService,
		PromiseService,
		PurchasableItemService,
		PurchaseLogService,
		QmsDocumentService,
		QmsDocumentVersionService,
		RequestForQuoteService,
		SalesEnquiryService,
		SalesOrderService,
		SalesOrderPartService,
		SiteService,
		SmsService,
		SmsTemplateService,
		StaffAttendanceService,
		StockStoreService,
		StockStoreHistoryService,
		SupplierContactService,
		SupplierService,
		SupplierDocumentService,
		SupplierLocationService,
		SupplierPurchaseTypeService,
		SystemParameterService,
		ToolService,
		ToolEventService,
		TrainingService,
		UserService,
		UserProfileService,
		VisitService,
		VisitorService,
		WorkOrderService,
		WorkOrderStageService,
		WorkOrderStageInspectionService,
		{
			multi: true,
			provide: HTTP_INTERCEPTORS,
			useClass: HttpClientInterceptor
		}
	]
})
export class AppModule {}