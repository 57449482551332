<admin-header [primaryButtons]="headerPrimaryButtons" [secondaryButtons]="headerSecondaryButtons" [tertiaryButtons]="headerTertiaryButtons" [title]="title" (eventFired)="headerButtonClick($event)"></admin-header>
<div class="form-container">
	<dx-form class="m-3" [(formData)]="item" [showColonAfterLabel]="true" labelLocation="top" [readOnly]="readOnly">
		<dxi-item itemType="tabbed" [tabPanelOptions]="{hoverStateEnabled:false}">
			<dxo-tab-panel-options [deferRendering]="false"></dxo-tab-panel-options>
			<dxi-tab title="Work Order" [colCount]="8">
				<dxi-item dataField="id" [colSpan]="2" [label]="{ text: 'Work Order Id' }" [editorOptions]="{ readOnly: true}">
				</dxi-item>
				<dxi-item dataField="cocNumber" [colSpan]="2" [label]="{ text: 'COC Number' }">
					<dxi-validation-rule type="required"></dxi-validation-rule>
				</dxi-item>
				<dxi-item dataField="dateRequired" [colSpan]="2" editorType="dxDateBox" [label]="{ text: 'Date Required' }" [editorOptions]="{ displayFormat: 'dd/MM/yyyy', type: 'date' }">
					<dxi-validation-rule type="required"></dxi-validation-rule>
				</dxi-item>
				<dxi-item dataField="quantity" [colSpan]="2" [editorOptions]="{ readOnly: true }"></dxi-item>
				<dxi-item dataField="specialInstructions" [colSpan]="8" editorType="dxTextArea" [editorOptions]="{ height: 90 }"></dxi-item>
				<dxi-item *ngIf="item.originalWorkOrderId != undefined && item.originalWorkOrderId > 0" [colSpan]="8">This work order was split from <a href="/admin/workOrders/{{item.originalWorkOrderId}}">Work Order {{item.originalWorkOrderId}}</a></dxi-item>
				<dxi-item *ngIf="splitWorkOrders.length > 0" [colSpan]="8">Additional Work Orders have been split off from this one. Please see Splits tab for details.</dxi-item>
				<dxi-item *ngIf="item.parentWorkOrderId != undefined && item.parentWorkOrderId > 0" [colSpan]="8">This is a child of <a href="/admin/workOrders/{{item.parentWorkOrderId}}">Work Order {{item.parentWorkOrderId}}</a> and will need finishing before that one can be completed.</dxi-item>
				<dxi-item *ngIf="childWorkOrders.length > 0" [colSpan]="8">This work order has child work orders which need completing before this one can be finished. See Children tab for details.</dxi-item>
			</dxi-tab>
			<dxi-tab *ngIf="splitWorkOrders.length > 0"caption="Splits">
				<dx-data-grid class="data-grid" [dataSource]="splitWorkOrders" [remoteOperations]="false" [allowColumnReordering]="true" [allowColumnResizing]="true" [rowAlternationEnabled]="true" [showBorders]="true">
					<dxo-paging [pageSize]="100"></dxo-paging>
					<dxo-state-storing [enabled]="true" type="localStorage" storageKey="woSplitOrders"></dxo-state-storing>
					<dxo-pager [visible]="true" [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
					<dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"></dxo-search-panel>
					<dxo-group-panel [visible]="true"></dxo-group-panel>
					<dxo-grouping [autoExpandAll]="false"></dxo-grouping>
					<dxo-export [enabled]="true"></dxo-export>
					<dxi-column type="buttons">
						<dxi-button hint="View" icon="fal fa-eye" [onClick]="viewSplitRow"></dxi-button>
						<dxi-button hint="Edit" icon="fal fa-pencil" [onClick]="editSplitRow"></dxi-button>
					</dxi-column>
					<dxi-column dataField="id" dataType="string" alignment="left"></dxi-column>
					<dxi-column dataField="cocNumber" dataType="string" alignment="left"></dxi-column>
				</dx-data-grid>
			</dxi-tab>
			<dxi-tab *ngIf="childWorkOrders.length > 0" title="Children">
				<dx-data-grid class="data-grid" [dataSource]="childWorkOrders" [remoteOperations]="false" [allowColumnReordering]="true" [allowColumnResizing]="true" [rowAlternationEnabled]="true" [showBorders]="true">
					<dxo-paging [pageSize]="100"></dxo-paging>
					<dxo-state-storing [enabled]="true" type="localStorage" storageKey="woChildOrders"></dxo-state-storing>
					<dxo-pager [visible]="true" [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
					<dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"></dxo-search-panel>
					<dxo-group-panel [visible]="true"></dxo-group-panel>
					<dxo-grouping [autoExpandAll]="false"></dxo-grouping>
					<dxo-export [enabled]="true"></dxo-export>
					<dxi-column type="buttons">
						<dxi-button hint="View" icon="fal fa-eye" [onClick]="viewChildRow"></dxi-button>
						<dxi-button hint="Edit" icon="fal fa-pencil" [onClick]="editChildRow"></dxi-button>
					</dxi-column>
					<dxi-column dataField="id" dataType="string" alignment="left"></dxi-column>
					<dxi-column dataField="cocNumber" dataType="string" alignment="left"></dxi-column>
					<dxi-column dataField="part.partNumber" dataType="string" alignment="left" caption="Part"></dxi-column>
				</dx-data-grid>
			</dxi-tab>
			<dxi-tab title="Sales Order">
				<dx-form [(formData)]="salesOrder" [showColonAfterLabel]="true" labelLocation="top" [colCount]="2" [readOnly]="true">
					<dxi-item dataField="poNumber" [label]="{ text: 'PO Number' }"></dxi-item>
					<dxi-item dataField="mrdDate" editorType="dxDateBox" [editorOptions]="{ displayFormat: 'dd/MM/yyyy', type: 'date' }" [label]="{ text: 'MRD Date' }"></dxi-item>
				</dx-form>
			</dxi-tab>
			<dxi-tab title="Part">
				<dx-form [(formData)]="part" [showColonAfterLabel]="true" labelLocation="top" [colCount]="6" [readOnly]="true">
					<dxi-item [colSpan]="3" dataField="partNumber"></dxi-item>
					<dxi-item [colSpan]="3" dataField="materialId" editorType="dxSelectBox" [editorOptions]="{ items: purchasableItems, searchEnabled: true, displayExpr:'name', valueExpr:'id' }" [label]="{ text: 'Material' }"></dxi-item>
					<dxi-item [colSpan]="2" dataField="gauge" [label]="{ text: 'Gauge' }"></dxi-item>
					<dxi-item [colSpan]="2" dataField="blankLength" editorType="dxNumberBox" [label]="{ text: 'Blank Length (mm)' }" [editorOptions]="{ format : '#0'}"></dxi-item>
					<dxi-item [colSpan]="2" dataField="blankWidth" editorType="dxNumberBox" [label]="{ text: 'Blank Width (mm)' }" [editorOptions]="{ format : '#0'}"></dxi-item>
				</dx-form>
			</dxi-tab>
			<dxi-tab *ngIf="fixingsRequired.length > 0" title="Fixings">
				<dxi-item>Fixings required per-part</dxi-item>
				<dx-data-grid #fixingsGridContainer class="data-grid" [dataSource]="fixingsRequired" [remoteOperations]="false" [allowColumnReordering]="false" [rowAlternationEnabled]="true" [showBorders]="true" height="200px">
					<dxo-paging [pageSize]="100"></dxo-paging>
					<dxo-state-storing [enabled]="true" type="localStorage" storageKey="woFixings"></dxo-state-storing>
					<dxo-pager [visible]="false" [showPageSizeSelector]="false" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
					<dxo-search-panel [visible]="false" [highlightCaseSensitive]="true"></dxo-search-panel>
					<dxo-group-panel [visible]="false"></dxo-group-panel>
					<dxo-grouping [autoExpandAll]="false"></dxo-grouping>
					<dxo-export [enabled]="false"></dxo-export>
					<dxi-column dataField="purchasableItemId" dataType="number" alignment="left" caption="Fixing">
						<dxo-lookup [dataSource]="purchasableItems" displayExpr="name" valueExpr="id"></dxo-lookup>
					</dxi-column>
					<dxi-column dataField="quantity" dataType="number" alignment="left" caption="QTY" [width]="50"></dxi-column>
				</dx-data-grid>
			</dxi-tab>
			<dxi-tab *ngIf="consumablesRequired.length > 0" title="Consumables">
				<dxi-item>Consumables required per-part</dxi-item>
				<dx-data-grid #consumablesGridContainer class="data-grid" [dataSource]="consumablesRequired" [remoteOperations]="false" [allowColumnReordering]="false" [rowAlternationEnabled]="true" [showBorders]="true" height="200px">
					<dxo-paging [pageSize]="100"></dxo-paging>
					<dxo-state-storing [enabled]="true" type="localStorage" storageKey="woConsumables"></dxo-state-storing>
					<dxo-pager [visible]="false" [showPageSizeSelector]="false" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
					<dxo-search-panel [visible]="false" [highlightCaseSensitive]="true"></dxo-search-panel>
					<dxo-group-panel [visible]="false"></dxo-group-panel>
					<dxo-grouping [autoExpandAll]="false"></dxo-grouping>
					<dxo-export [enabled]="false"></dxo-export>
					<dxi-column dataField="purchasableItemId" dataType="number" alignment="left" caption="Fixing">
						<dxo-lookup [dataSource]="purchasableItems" displayExpr="name" valueExpr="id"></dxo-lookup>
					</dxi-column>
					<dxi-column dataField="quantity" dataType="number" alignment="left" caption="QTY" [width]="50"></dxi-column>
				</dx-data-grid>
			</dxi-tab>
			<dxi-tab title="Progress">
				<dx-data-grid class="data-grid" [dataSource]="workOrderStages" [remoteOperations]="false" [allowColumnReordering]="false" [rowAlternationEnabled]="true" [showBorders]="true" height="200px">
					<dxo-paging [pageSize]="100"></dxo-paging>
					<dxo-state-storing [enabled]="true" type="localStorage" storageKey="woProgress"></dxo-state-storing>
					<dxo-pager [visible]="false" [showPageSizeSelector]="false" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
					<dxo-search-panel [visible]="false" [highlightCaseSensitive]="true"></dxo-search-panel>
					<dxo-group-panel [visible]="false"></dxo-group-panel>
					<dxo-grouping [autoExpandAll]="false"></dxo-grouping>
					<dxo-export [enabled]="false"></dxo-export>
					<dxi-column dataField="stageNumber" dataType="number" alignment="left" caption="Stage" [width]="50"></dxi-column>
					<dxi-column dataField="process.name" dataType="string" alignment="left" caption="Process"></dxi-column>
					<dxi-column dataField="assignedUserProfileId" dataType="number" alignment="left" caption="Responsible User">
						<dxo-lookup [dataSource]="people" displayExpr="fullName" valueExpr="id"></dxo-lookup>
					</dxi-column>
					<dxi-column dataField="quantityCompleted" dataType="number" alignment="right" caption="QC" [width]="50"></dxi-column>
					<dxi-column dataField="quantityRequired" dataType="number" alignment="right" caption="QR" [width]="50"></dxi-column>
					<dxi-column dataField="inspectionsPassed" dataType="number" alignment="right" caption="IC" [width]="50"></dxi-column>
					<dxi-column dataField="inspectionsRequired" dataType="number" alignment="right" caption="IR" [width]="50"></dxi-column>
					<dxi-column dataField="qualityControlCheckType" dataType="number" alignment="left" caption="Inspection Type">
						<dxo-lookup [dataSource]="qualityControlCheckTypes" displayExpr="name" valueExpr="id"></dxo-lookup>
					</dxi-column>
					<dxi-column type="buttons">
						<dxi-button hint="Increase Completed Quantity" icon="fal fa-plus" [visible]="isincrementWorkOrderStagePopupButtonAvailable" [onClick]="incrementWorkOrderStagePopup"></dxi-button>
					</dxi-column>
				</dx-data-grid>	
			</dxi-tab>
			<dxi-tab title="Documents">
				<dxi-item itemType="group" [colSpan]="1" [colCount]="1">
					<dxi-item itemType="button" [buttonOptions]="supportingDocumentButtonOptions"></dxi-item>
				</dxi-item>
				<dxi-item itemType="group" [colSpan]="1" [colCount]="1">
					<dx-data-grid class="data-grid" [dataSource]="supportingDocuments" [remoteOperations]="false" [allowColumnReordering]="false" [rowAlternationEnabled]="true" [showBorders]="true" height="200px">
						<dxo-paging [pageSize]="100"></dxo-paging>
						<dxo-state-storing [enabled]="true" type="localStorage" storageKey="workOrderSupportingDocuments"></dxo-state-storing>
						<dxo-pager [visible]="false" [showPageSizeSelector]="false" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
						<dxo-search-panel [visible]="false" [highlightCaseSensitive]="true"></dxo-search-panel>
						<dxo-group-panel [visible]="false"></dxo-group-panel>
						<dxo-grouping [autoExpandAll]="false"></dxo-grouping>
						<dxo-export [enabled]="false"></dxo-export>
						<dxi-column dataField="fileName" dataType="text" alignment="left" caption="File"></dxi-column>
						<dxi-column dataField="description" dataType="text" alignment="left" caption="Description"></dxi-column>
						<dxi-column type="buttons">
							<dxi-button hint="Download" icon="fal fa-download" [onClick]="downloadFileLink"></dxi-button>
							<dxi-button hint="Delete" icon="fal fa-trash" [onClick]="deleteSupportingDocumentLink"></dxi-button>
						</dxi-column>
					</dx-data-grid>
				</dxi-item>
			</dxi-tab>
			<dxi-tab title="Ops Audits">
				<dx-data-grid class="data-grid" [dataSource]="opsActionAudits" [remoteOperations]="false" [allowColumnReordering]="false" [rowAlternationEnabled]="true" [showBorders]="true" height="500px">
					<dxo-paging [pageSize]="100"></dxo-paging>
					<dxo-pager [visible]="true" [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
					<dxo-search-panel [visible]="false" [highlightCaseSensitive]="true"></dxo-search-panel>
					<dxo-group-panel [visible]="false"></dxo-group-panel>
					<dxo-grouping [autoExpandAll]="false"></dxo-grouping>
					<dxo-export [enabled]="false"></dxo-export>
					<dxi-column dataField="createdAt" dataType="datetime" format="dd/MM/yyyy HH:mm:ss" alignment="left" caption="Date"></dxi-column>
					<dxi-column dataField="workOrderId" dataType="text" alignment="left" caption="Work Order"></dxi-column>
					<dxi-column dataField="operationNumber" dataType="text" alignment="left" caption="OP"></dxi-column>
					<dxi-column dataField="part.partNumber" dataType="text" alignment="left" caption="Part"></dxi-column>
					<dxi-column dataField="machine.cellNumber" dataType="text" alignment="left" caption="Cell"></dxi-column>
					<dxi-column dataField="machine.name" dataType="text" alignment="left" caption="Machine"></dxi-column>
					<dxi-column dataField="operator.fullName" dataType="text" alignment="left" caption="Operator"></dxi-column>
					<dxi-column dataField="quantity" dataType="number" alignment="left" caption="Qty"></dxi-column>
					<dxi-column dataField="description" dataType="text" alignment="left" caption="Description"></dxi-column>
					<dxi-column dataField="userProfile.fullName" dataType="text" alignment="left" caption="User"></dxi-column>
				</dx-data-grid>
			</dxi-tab>
		</dxi-item>
	</dx-form>
	<dx-popup [width]="'75%'" [height]="600" [showTitle]="true" [title]="popupTitle" [dragEnabled]="false" [closeOnOutsideClick]="false" [showCloseButton]="false" container=".dx-viewport" [(visible)]="popupVisible">
		<dxi-toolbar-item widget="dxButton" toolbar="bottom" location="before" [options]="popupAddButtonOptions"></dxi-toolbar-item>
		<dxi-toolbar-item widget="dxButton" toolbar="bottom" location="after" [options]="popupCloseButtonOptions"></dxi-toolbar-item>
		<div *dxTemplate="let data of 'content'">
			<div *ngIf="popupTitle == 'Add New Supporting Document'">
				<dx-form class="m-3" [(formData)]="popupSupportingDocument" [showColonAfterLabel]="true" labelLocation="top">
					<dxi-item dataField="description">
						<dxi-validation-rule type="required" message="Description is required"></dxi-validation-rule>					
					</dxi-item>
					<dxi-item>
						<dx-file-uploader [maxFileSize]="2097152" [uploadFile]="uploadSupportingDocument"></dx-file-uploader>
					</dxi-item>
					<dxi-item>
						<p>Adding this document will save all outstanding changes on the Work Order. Please ensure all details are correct before uploading.</p>
					</dxi-item>
				</dx-form>
			</div>
			<div *ngIf="popupTitle.includes('Increase Completed Quantity for Stage ')">
				<dx-form class="m-3" [(formData)]="popupWorkOrderStageIncrementData" [showColonAfterLabel]="true" labelLocation="top">
					<dxi-item dataField="quantity" [label]="{ text: 'Additional Completed Quantity' }">
						<dxi-validation-rule type="required" message="Quantity is required"></dxi-validation-rule>					
					</dxi-item>
					<dxi-item dataField="maximumQuantity" [editorOptions]="{ readOnly: true }" [label]="{ text: 'Maximum Value' }"></dxi-item>
					<dxi-item>
						<p>This cannot be undone, be very careful to ensure this is correct.</p>
					</dxi-item>
				</dx-form>
			</div>
		</div>
	</dx-popup>
</div>