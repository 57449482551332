<admin-header [primaryButtons]="headerPrimaryButtons" [tertiaryButtons]="headerTertiaryButtons" [title]="title" (eventFired)="headerButtonClick($event)"></admin-header>
<div class="form-container">
	<div id="currentlyAtSite" *ngIf="currentlyAtSite !== ''">
		Currently on site at {{currentlyAtSite}}
	</div>
	<dx-form class="m-3" [(formData)]="item" [showColonAfterLabel]="true" labelLocation="top" [readOnly]="readOnly">
		<dxi-item itemType="tabbed" [tabPanelOptions]="{hoverStateEnabled:false}">
			<dxo-tab-panel-options [deferRendering]="false"></dxo-tab-panel-options>
			<dxi-tab title="User Details" [colCount]="6">
				<dxi-item dataField="email" [colSpan]="3" [editorOptions]="{ disabled: disableEmailAddress }"><dxo-label text="Email Address"></dxo-label></dxi-item>
				<dxi-item dataField="telephoneNumber" [colSpan]="3"></dxi-item>
				<dxi-item dataField="title" editorType="dxSelectBox" [editorOptions]="{ items: titles, searchEnabled: true }" [colSpan]="2"></dxi-item>
				<dxi-item dataField="firstName" [colSpan]="2" ></dxi-item>
				<dxi-item dataField="lastName" [colSpan]="2"></dxi-item>
				<dxi-item dataField="dateOfBirth" [colSpan]="3"></dxi-item>
				<dxi-item dataField="gender" [colSpan]="3"></dxi-item>
				<dxi-item dataField="userCode" [colSpan]="3" [editorOptions]="{mode: 'password'}"></dxi-item>
				<dxi-item dataField="fobId" [colSpan]="3"></dxi-item>
				<dxi-item dataField="supervisorId" editorType="dxSelectBox" [editorOptions]="{ items: supervisors, displayExpr:'name', valueExpr:'id', searchEnabled: true }" [colSpan]="3"><dxo-label text="Supervisor"></dxo-label></dxi-item>
				<dxi-item dataField="shiftId" editorType="dxSelectBox" [editorOptions]="{ items: shifts, displayExpr:'name', valueExpr:'id' }" [colSpan]="3"><dxo-label text="Shift"></dxo-label></dxi-item>
				<dxi-item dataField="siteId" editorType="dxSelectBox" [editorOptions]="{ items: sites, displayExpr:'name', valueExpr:'id' }" [colSpan]="2"><dxo-label text="Site"></dxo-label></dxi-item>
				<dxi-item dataField="defaultDepartment" editorType="dxSelectBox" [editorOptions]="{ items: departments, displayExpr:'name', valueExpr:'id' }" [colSpan]="2"><dxo-label text="Default Department"></dxo-label></dxi-item>
				<dxi-item dataField="currentDepartment" editorType="dxSelectBox" [editorOptions]="{ items: departments, displayExpr:'name', valueExpr:'id' }" [colSpan]="2"><dxo-label text="Current Department"></dxo-label></dxi-item>
				<dxi-item dataField="jobTitle" [colSpan]="2"></dxi-item>
				<dxi-item dataField="defaultHolidayAllowance" [editorOptions]="{ readOnly: holidayAllowanceReadOnly }" [colSpan]="3"><dxo-label text="Default Yearly Holiday Allowance (days inc half day)"></dxo-label></dxi-item>
				<dxi-item *ngIf="isSuperAdmin && mode=='create'" [label]="{ text: 'Non-admin Staff (don\'t add to admin group or send completion email)'}" dataField="markCompleted" editorType="dxCheckBox" [colSpan]="3"></dxi-item>
			</dxi-tab>
			<dxi-tab *ngIf="mode!='create'" title="Training">
				<dx-data-grid class="data-grid" id="gridContainer" [dataSource]="training" [remoteOperations]="false" [allowColumnReordering]="true" [allowColumnResizing]="true" [rowAlternationEnabled]="true" [showBorders]="true">
					<dxo-paging [pageSize]="100"></dxo-paging>
					<dxo-state-storing [enabled]="true" type="localStorage" storageKey="userTraining"></dxo-state-storing>
					<dxo-pager [visible]="true" [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
					<dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"></dxo-search-panel>
					<dxo-group-panel [visible]="true"></dxo-group-panel>
					<dxo-grouping [autoExpandAll]="false"></dxo-grouping>
					<dxo-export [enabled]="true"></dxo-export>
					<dxi-column type="buttons">
						<dxi-button hint="Download" icon="fal fa-download" [onClick]="downloadCertificate"></dxi-button>
						<dxi-button hint="Delete" icon="fal fa-trash" [onClick]="deleteTraining"></dxi-button>
					</dxi-column>
					<dxi-column dataField="name" dataType="string" alignment="left"></dxi-column>
					<dxi-column dataField="machineName" dataType="string" alignment="left"></dxi-column>
					<dxi-column dataField="certificateFileName" dataType="string" alignment="left" caption="File"></dxi-column>
					<dxi-column dataField="trainingDate" dataType="date" format="dd/MM/yyy" alignment="left"></dxi-column>
					<dxi-column dataField="expiryDate" dataType="date" format="dd/MM/yyy" alignment="left"></dxi-column>
					<dxi-column dataField="trainingStatus" dataType="string" alignment="left"><dxo-lookup [dataSource]="trainingStatuses" valueExpr="id" displayExpr="name"></dxo-lookup></dxi-column>
				</dx-data-grid>
			</dxi-tab>
			<dxi-tab title="User Groups" *ngIf="groups.length > 0 && mode!='create'">
				<dx-data-grid class="data-grid" id="gridContainer" [dataSource]="groups" [remoteOperations]="false" [allowColumnReordering]="true" [allowColumnResizing]="true" [rowAlternationEnabled]="true" [showBorders]="true">
					<dxo-paging [pageSize]="100"></dxo-paging>
					<dxo-state-storing [enabled]="true" type="localStorage" storageKey="userGroups"></dxo-state-storing>
					<dxo-pager [visible]="true" [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
					<dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"></dxo-search-panel>
					<dxo-group-panel [visible]="true"></dxo-group-panel>
					<dxo-grouping [autoExpandAll]="false"></dxo-grouping>
					<dxo-export [enabled]="true"></dxo-export>
					<dxi-column type="buttons" [(visible)]="isSuperAdmin">
						<dxi-button hint="Add Group" icon="fal fa-toggle-off" [onClick]="toggleUserGroup" [visible]="isToggleGroupOnVisible"></dxi-button>
						<dxi-button hint="Remove Group" icon="fal fa-toggle-on" [onClick]="toggleUserGroup" [visible]="isToggleGroupOffVisible"></dxi-button>
					</dxi-column>
					<dxi-column dataField="name" dataType="string" alignment="left"></dxi-column>
					<dxi-column dataField="isSelected" dataType="string" alignment="left" [customizeText]="boolYesNo"></dxi-column>
				</dx-data-grid>
			</dxi-tab>
			<dxi-tab *ngIf="mode!='create'" title="Attendance">
				<dx-data-grid class="data-grid" id="gridContainer" [dataSource]="attendance" [remoteOperations]="false" [allowColumnReordering]="true" [allowColumnResizing]="true" [rowAlternationEnabled]="true" [showBorders]="true">
					<dxo-paging [pageSize]="100"></dxo-paging>
					<dxo-state-storing [enabled]="true" type="localStorage" storageKey="userAttendance"></dxo-state-storing>
					<dxo-pager [visible]="true" [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
					<dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"></dxo-search-panel>
					<dxo-group-panel [visible]="true"></dxo-group-panel>
					<dxo-grouping [autoExpandAll]="false"></dxo-grouping>
					<dxo-export [enabled]="true"></dxo-export>
					<dxi-column dataField="site.name" dataType="string" alignment="left" caption="Site"></dxi-column>
					<dxi-column dataField="startDate" dataType="datetime" format="dd/MM/yyyy HH:mm:ss" alignment="left"></dxi-column>
					<dxi-column dataField="endDate" dataType="datetime" format="dd/MM/yyyy HH:mm:ss" alignment="left"></dxi-column>
					<dxi-column dataField="timeInMinutes" dataType="number" alignment="left"></dxi-column>
				</dx-data-grid>
			</dxi-tab>
			<dxi-tab title="Ops Audits (operator)">
				<dx-data-grid class="data-grid" [dataSource]="opsActionAuditsOperator" [remoteOperations]="false" [allowColumnReordering]="false" [rowAlternationEnabled]="true" [showBorders]="true" height="500px">
					<dxo-paging [pageSize]="100"></dxo-paging>
					<dxo-pager [visible]="true" [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
					<dxo-search-panel [visible]="false" [highlightCaseSensitive]="true"></dxo-search-panel>
					<dxo-group-panel [visible]="false"></dxo-group-panel>
					<dxo-grouping [autoExpandAll]="false"></dxo-grouping>
					<dxo-export [enabled]="false"></dxo-export>
					<dxi-column dataField="createdAt" dataType="datetime" format="dd/MM/yyyy HH:mm:ss" alignment="left" caption="Date"></dxi-column>
					<dxi-column dataField="workOrderId" dataType="text" alignment="left" caption="Work Order"></dxi-column>
					<dxi-column dataField="operationNumber" dataType="text" alignment="left" caption="OP"></dxi-column>
					<dxi-column dataField="part.partNumber" dataType="text" alignment="left" caption="Part"></dxi-column>
					<dxi-column dataField="machine.cellNumber" dataType="text" alignment="left" caption="Cell"></dxi-column>
					<dxi-column dataField="machine.name" dataType="text" alignment="left" caption="Machine"></dxi-column>
					<dxi-column dataField="operator.fullName" dataType="text" alignment="left" caption="Operator"></dxi-column>
					<dxi-column dataField="quantity" dataType="number" alignment="left" caption="Qty"></dxi-column>
					<dxi-column dataField="description" dataType="text" alignment="left" caption="Description"></dxi-column>
					<dxi-column dataField="userProfile.fullName" dataType="text" alignment="left" caption="User"></dxi-column>
				</dx-data-grid>
			</dxi-tab>
			<dxi-tab title="Ops Audits (user)">
				<dx-data-grid class="data-grid" [dataSource]="opsActionAuditsUser" [remoteOperations]="false" [allowColumnReordering]="false" [rowAlternationEnabled]="true" [showBorders]="true" height="500px">
					<dxo-paging [pageSize]="100"></dxo-paging>
					<dxo-pager [visible]="true" [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
					<dxo-search-panel [visible]="false" [highlightCaseSensitive]="true"></dxo-search-panel>
					<dxo-group-panel [visible]="false"></dxo-group-panel>
					<dxo-grouping [autoExpandAll]="false"></dxo-grouping>
					<dxo-export [enabled]="false"></dxo-export>
					<dxi-column dataField="createdAt" dataType="datetime" format="dd/MM/yyyy HH:mm:ss" alignment="left" caption="Date"></dxi-column>
					<dxi-column dataField="workOrderId" dataType="text" alignment="left" caption="Work Order"></dxi-column>
					<dxi-column dataField="operationNumber" dataType="text" alignment="left" caption="OP"></dxi-column>
					<dxi-column dataField="part.partNumber" dataType="text" alignment="left" caption="Part"></dxi-column>
					<dxi-column dataField="machine.cellNumber" dataType="text" alignment="left" caption="Cell"></dxi-column>
					<dxi-column dataField="machine.name" dataType="text" alignment="left" caption="Machine"></dxi-column>
					<dxi-column dataField="operator.fullName" dataType="text" alignment="left" caption="Operator"></dxi-column>
					<dxi-column dataField="quantity" dataType="number" alignment="left" caption="Qty"></dxi-column>
					<dxi-column dataField="description" dataType="text" alignment="left" caption="Description"></dxi-column>
					<dxi-column dataField="userProfile.fullName" dataType="text" alignment="left" caption="User"></dxi-column>
				</dx-data-grid>
			</dxi-tab>
		</dxi-item>
	</dx-form>
	<br/>
</div>
<dx-popup [width]="'75%'" [height]="600" [showTitle]="true" [title]="popupTitle" [dragEnabled]="false" [closeOnOutsideClick]="false" [showCloseButton]="false" container=".dx-viewport" [(visible)]="popupVisible">
	<dxi-toolbar-item widget="dxButton" toolbar="bottom" location="before" [options]="popupAddButtonOptions"></dxi-toolbar-item>
	<dxi-toolbar-item widget="dxButton" toolbar="bottom" location="after" [options]="popupCloseButtonOptions"></dxi-toolbar-item>
	<div class="col-6">
		<dx-file-uploader class="mb-3 mx-2" [chunkSize]="200000" [maxFileSize]="10485760" [uploadChunk]="uploadTrainingCertificate"></dx-file-uploader>
	</div>	
	<div class="col-6">
		<label>Training Name</label>
		<input class="form-control shadow-none" type="text" placeholder="Enter Name" name="name" [(ngModel)]="newTraining.name">
	</div>
	<div class="col-6">
		<label>Training Date</label>
		<input class="form-control shadow-none" type="date" placeholder="Training Date" name="trainingDate" [(ngModel)]="newTraining.trainingDate">
	</div>
	<div class="col-6">
		<label>Expiry Date</label>
		<input class="form-control shadow-none" type="date" placeholder="Expiry Date" name="expiryDate" [(ngModel)]="newTraining.expiryDate">
	</div>
	<div class="col-6">
		<label>Machine</label>		
		<select class="form-control shadow-none" name="machineId" [(ngModel)]="newTraining.machineId">
			<option *ngFor="let machine of machines" [value]="machine.id">{{machine.cellNumber}} - {{machine.name}}</option>
		</select>
	</div>
</dx-popup>