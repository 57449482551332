<admin-header [primaryButtons]="headerPrimaryButtons" [secondaryButtons]="headerSecondaryButtons" [title]="title" (eventFired)="headerButtonClick($event)"></admin-header>
<dx-data-grid class="data-grid" [dataSource]="item" [remoteOperations]="false" [allowColumnReordering]="true" [allowColumnResizing]="true" [rowAlternationEnabled]="true" [showBorders]="true" (onExporting)="logExport()">
    <dxo-paging [pageSize]="100"></dxo-paging>
	<dxo-state-storing [enabled]="true" type="localStorage" storageKey="userMain"></dxo-state-storing>
    <dxo-pager [visible]="true" [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
    <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"></dxo-search-panel>
    <dxo-group-panel [visible]="true"></dxo-group-panel>
    <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
	<dxo-export [enabled]="true"></dxo-export>
	<dxo-scrolling columnRenderingMode="virtual"></dxo-scrolling>
	<dxi-column type="buttons">
        <dxi-button hint="View" icon="fal fa-eye" [onClick]="viewRow"></dxi-button>
		<dxi-button hint="Edit" icon="fal fa-pencil" [onClick]="editRow" [visible]="editEnabled"></dxi-button>
		<dxi-button icon="fal fa-trash" [onClick]="deleteRow" [visible]="deleteEnabled"></dxi-button>
    </dxi-column>
    <dxi-column dataField="id" dataType="number" alignment="left"></dxi-column>
    <dxi-column dataField="firstName" dataType="string" alignment="left"></dxi-column>
    <dxi-column dataField="lastName" dataType="string" alignment="left"></dxi-column>
    <dxi-column dataField="email" dataType="string" alignment="left"></dxi-column>
	<dxi-column dataField="lastSeen" caption="Last Login" dataType="date" alignment="left" format="dd/MM/yyy"></dxi-column>
	<dxi-column dataField="shiftId" dataType="string" alignment="left" caption="Shift"><dxo-lookup [dataSource]="shifts" valueExpr="id" displayExpr="name"></dxo-lookup></dxi-column>
</dx-data-grid>