<admin-header [primaryButtons]="headerPrimaryButtons" [title]="title" (eventFired)="headerButtonClick($event)"></admin-header>
<div class="row">
	<div class="col-8">
		<div class="row">			
			<div *ngFor="let machine of machines" class="col-12 machineInfo">
				<h2 style="background-color: {{machine.siteColour}};">{{machine.cellNumber}}</h2>
				<p>{{machine.machineName}}</p>
				<p>{{machine.siteName}}</p>
				<table>
					<tbody>
						<tr>
							<th class="running" colspan="7">Running</th>
						</tr>
						<tr>
							<th scope="col">W/O</th>
							<th scope="col">Part</th>
							<th scope="col">Qty</th>
							<th scope="col">Operator</th>
							<th></th>
							<th></th>
							<th></th>
						</tr>
						<tr *ngFor="let workOrderStage of machine.runningStages">
							<th scope="row"><a href="/admin/workOrders/{{workOrderStage.workOrderId}}" style="background-color:#F8F8F8 !important; color:#344281!important">{{workOrderStage.workOrderId}}</a></th>
							<td>{{workOrderStage.partNumber}}</td>
							<td>{{workOrderStage.quantityRequired}}</td>	
							<td>{{workOrderStage.assignedUserProfileFullName}}</td>
							<td><a href="#" (click)="changeWorkOrderStageOperatorPopup(workOrderStage.id)"><i class="fal fa-user-edit"></i></a></td>
							<td></td>
							<td></td>
						</tr>
						<tr>
							<th class="available" colspan="7">Available</th>
						</tr>
						<tr>
							<th scope="col">W/O</th>
							<th scope="col">Part</th>
							<th scope="col">Qty</th>
							<th scope="col">Operator</th>
							<th></th>
							<th>Priority</th>
							<th>Move</th>
						</tr>					
						<tr *ngFor="let workOrderStage of machine.availableStages">
							<th scope="row"><a href="/admin/workOrders/{{workOrderStage.workOrderId}}" style="background-color:#F8F8F8 !important; color:#344281!important">{{workOrderStage.workOrderId}}</a></th>
							<td>{{workOrderStage.partNumber}}</td>
							<td>{{workOrderStage.quantityRequired}}</td>
							<td>{{workOrderStage.assignedUserProfileFullName}}</td>
							<td><a href="#" (click)="changeWorkOrderStageOperatorPopup(workOrderStage.id)"><i class="fal fa-user-edit"></i></a></td>
							<td>
								<a href="#" (click)="changeWorkOrderStagePriority(workOrderStage.id, workOrderStage.priority + 1)"><i class="fal fa-arrow-up"></i></a>
								<strong class="priority">{{workOrderStage.priority}}</strong>
								<a href="#" (click)="changeWorkOrderStagePriority(workOrderStage.id, workOrderStage.priority - 1)"><i class="fal fa-arrow-down"></i></a>
							</td>
							<td><a href="#" (click)="changeWorkOrderStageMachinePopup(workOrderStage.id)"><i class="fal fa-arrows-alt"></i></a></td>
						</tr>
						<tr>
							<th class="pending" colspan="7">Pending</th>
						</tr>
						<tr>
							<th scope="col">W/O</th>
							<th scope="col">Part</th>
							<th scope="col">Qty</th>
							<th scope="col">Operator</th>
							<th></th>
							<th></th>
							<th></th>
						</tr>
						<tr *ngFor="let workOrderStage of machine.pendingStages">
							<th scope="row"><a href="/admin/workOrders/{{workOrderStage.workOrderId}}" style="background-color:#F8F8F8 !important; color:#344281!important">{{workOrderStage.workOrderId}}</a></th>
							<td>{{workOrderStage.partNumber}}</td>
							<td>{{workOrderStage.quantityRequired}}</td>
							<td>{{workOrderStage.assignedUserProfileFullName}}</td>
							<td></td>
							<td></td>
							<td></td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
	<div class="col-4">
		<dx-data-grid class="data-grid" [dataSource]="userProfiles" [remoteOperations]="false" [allowColumnReordering]="false" [allowColumnResizing]="false" [rowAlternationEnabled]="true" [showBorders]="false">
			<dxo-paging [pageSize]="100"></dxo-paging>
			<dxo-pager [visible]="false" [showPageSizeSelector]="false" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
			<dxo-search-panel [visible]="false" [highlightCaseSensitive]="false"></dxo-search-panel>
			<dxo-group-panel [visible]="false"></dxo-group-panel>
			<dxo-grouping [autoExpandAll]="false"></dxo-grouping>
			<dxo-export [enabled]="false"></dxo-export>
			<dxi-column dataField="fullName" dataType="string" alignment="left" caption="Name"></dxi-column>
			<dxi-column dataField="shiftId" dataType="string" alignment="left" caption="Shift"><dxo-lookup [dataSource]="shifts" valueExpr="id" displayExpr="name"></dxo-lookup></dxi-column>
			<dxi-column dataField="jobTitle" dataType="string" alignment="left" caption="Job Title"></dxi-column>
			<dxi-column dataField="siteId" dataType="string" alignment="left" caption="Site"><dxo-lookup [dataSource]="sites" valueExpr="id" displayExpr="name"></dxo-lookup></dxi-column>
		</dx-data-grid>
	</div>
</div>
<dx-popup [width]="750" [height]="300" [showTitle]="true" [title]="popupTitle" [dragEnabled]="false" [closeOnOutsideClick]="false" [showCloseButton]="false" container=".dx-viewport" [(visible)]="popupVisible">
	<dxi-toolbar-item widget="dxButton" toolbar="bottom" location="before" [options]="popupAddButtonOptions"></dxi-toolbar-item>
	<dxi-toolbar-item widget="dxButton" toolbar="bottom" location="after" [options]="popupCloseButtonOptions"></dxi-toolbar-item>
	<div *ngIf="popupTitle === 'Change Machine'">
		<dx-form class="m-3" [(formData)]="popupChangeWorkOrderStageMachine" [showColonAfterLabel]="true" labelLocation="top">
			<dxi-item dataField="machineId" editorType="dxSelectBox" [editorOptions]="{ items: machines, searchEnabled: true, displayExpr: displayMachineInDropdown, valueExpr:'machineId' }" [label]="{ text: 'Machine' }"></dxi-item>
		</dx-form>
	</div>
	<div *ngIf="popupTitle === 'Change Operator'">
		<dx-form class="m-3" [(formData)]="popupChangeWorkOrderStageOperator" [showColonAfterLabel]="true" labelLocation="top">
			<dxi-item dataField="userProfileId" editorType="dxSelectBox" [editorOptions]="{ items: userProfiles, searchEnabled: true, displayExpr:'fullName', valueExpr:'id' }" [label]="{ text: 'User' }"></dxi-item>
		</dx-form>
	</div>
</dx-popup>