<div class="operator-bg">
	<div id="mode" class="popup" *ngIf="showMode">
		<div (click)="changeMode(10)"
			style="padding-top:20px; margin-left:32px; margin-top:20px; width:750px; height:125px; border:5px solid #A7FFA7; background-color: #07B500;  text-align: center;">
			<span style="font-size: 60px; color:white;">PRODUCTION</span>
		</div>
		<div (click)="changeMode(20)"
			style="padding-top:20px; margin-left:32px; margin-top:20px; width:750px; height:125px; border:5px solid #FFEBA7; background-color: #EB9A00;  text-align: center;">
			<span style="font-size: 60px; color:white;">SETUP</span>
		</div>
		<div (click)="changeMode(30)"
			style="padding-top:20px; margin-left:32px; margin-top:20px; width:750px; height:125px; border:5px solid #FFA7A7; background-color: #EB0000;  text-align: center;">
			<span style="font-size: 60px; color:white;">NOT RUNNING</span>
		</div>
		<div (click)="toggleMode()"
			style=" padding-top:20px;margin-left:32px; margin-top:40px; width:750px; height:125px; border:5px solid #a7ddff; background-color: #0076EB;  text-align: center;">
			<span style="font-size: 50px; color:white;">CANCEL</span>
		</div>
	</div>
	<div id="complete" class="popup" style="height:474px; margin-top:0px;" *ngIf="showComplete">
		<div style="height:40px;"></div>
		<span style="font-size:40px; text-align: left; color:white; margin-left:42px; margin-top:40px !important;">Enter Quantity Complete...</span>
		<div class="row">
			<input class="input-override" type="number" name="quantity" (keyup.enter)="submitCompleted()" [(ngModel)]="quantityComplete" placeholder="Quantity"
		autocomplete="off"
		style="margin-left:42px; width: 762px;margin-top:50px;border-radius:0px; height:100px; font-size: 40px;">
		</div>
		<div class="row">
			<div (click)="submitCompleted()"
				style="padding-top:20px; margin-left:42px; margin-top:40px; width:365px; height:125px; border:5px solid #A7FFA7; background-color: #07B500;  text-align: center;">
				<span style="font-size: 60px; color:white">SUBMIT</span>
			</div>
			<div (click)="toggleComplete()"
				style="padding-top:20px; margin-left:32px; margin-top:40px; width:365px; height:125px; border:5px solid #a7ddff; background-color: #0076EB;  text-align: center;">
				<span style="font-size: 50px; color:white">CANCEL</span>
			</div>
		</div>
	</div>
	<div id="supervisor" class="popup" *ngIf="showSupervisor">
		<p style="font-size:80px; text-align: center; color:white">A SUPERVISOR HAS BEEN NOTIFIED</p>
		<p style="font-size:80px; text-align: center;color:white">THANK YOU</p>
		<div (click)="toggleSupervisor()"
			style="padding-top:20px; margin-left:32px; margin-top:40px; width:750px; height:125px; border:5px solid #a7ddff; background-color: #0076EB;  text-align: center;">
			<span style="font-size: 50px; color:white">OK</span>
		</div>
	</div>
	<div class="popup-blackout" *ngIf="showPopup"></div>
	<div *ngIf="currentStatus==10 || currentStatus == undefined" id="statusBar" class="status-active"></div>
	<div *ngIf="currentStatus==20" id="statusBar" class="status-idle"></div>
	<div *ngIf="currentStatus>29" id="statusBar" class="status-maintenance"></div>
	<div class="row" style="margin-left:20px">
		<div id="cellDetails"
			style="margin-left:32px;margin-top:20px;padding-left:22px;padding-top:10px;padding-bottom:10px; background-color: white; height:110px; width:477px">
			<p style="font-size: 20px;">Cell</p>
			<p style="font-size: 70px; margin-top:-30px">{{this.machine.cellNumber}}</p>
		</div>
		<div id="cellDetails"
			style="margin-left:22px;margin-top:20px;padding-left:22px;padding-top:10px;padding-bottom:10px; background-color: white; height:110px; width:477px">
			<p style="font-size: 20px;">Operator</p>
			<p style="font-size: 40px; margin-top:-10px">{{this.operatorName}}</p>
		</div>
	</div>
	<div class="row" style="margin-left:20px">
		<div class="col">
			<div id="cellDetails"
				style="margin-left:22px;margin-top:25px;padding-left:22px;padding-top:10px;padding-bottom:10px; background-color: white; height:110px; width:618px">
				<p style="font-size: 20px;">Part Number</p>
				<p style="font-size: 40px; margin-top:0px">{{this.part.partNumber}}</p>
			</div>
			<div class="row">
				<div id="cellDetails"
					style="margin-left:32px;margin-top:25px;padding-left:22px;padding-top:10px;padding-bottom:10px; background-color: white; height:110px; width:415px">
					<p style="font-size: 20px;">Client</p>
					<p style="font-size: 30px; margin-top:0px">{{this.clientName}}</p>
				</div>
				<div id="cellDetails"
					style="margin-left:25px;margin-top:25px;padding-left:22px;padding-top:10px;padding-bottom:10px; background-color: white; height:110px; width:180px">
					<p style="font-size: 20px;">Work Order</p>
					<p style="font-size: 30px; margin-top:0px">{{this.availableStages[0].workOrderId}}</p>
				</div>
			</div>
			<div class="row">
				<div id="cellDetails"
					style="margin-left:32px;margin-top:25px;padding-left:22px;padding-top:10px;padding-bottom:10px; background-color: white; height:110px; width:415px">
					<p style="font-size: 20px;">Part Progress</p>
					<p style="font-size: 50px; margin-top:-15px">{{this.availableStages[0].quantityCompleted}} of {{this.availableStages[0].quantityRequired}}</p>
				</div>
				<div id="cellDetails"
					style="margin-left:25px;margin-top:25px;padding-left:22px;padding-top:10px;padding-bottom:10px; background-color: white; height:110px; width:180px">
					<p style="font-size: 20px;">Op Number</p>
					<p style="font-size: 30px; margin-top:0px">{{this.stageNumber}}</p>
				</div>
			</div>
		</div>
		<div class="col">
			<img style="width:332px; height:378px; margin-top:25px" [src]="this.partImage">
		</div>
	</div>
	<div class="row" style="margin-left:20px">
		<div *ngIf="currentStatus!=10"
			style=" margin-left:32px; margin-top:20px; width:252px; height:130px; border:5px solid #a7a7a7; background-color: #717171; text-align: center; vertical-align: middle;">
			<i class="fa fa-check" style="font-size: 80px; color:white; margin-top:10px;"></i>
		</div>
		<div (click)="toggleComplete()" *ngIf="currentStatus==10"
			style=" margin-left:32px; margin-top:20px; width:252px; height:130px; border:5px solid #A7FFA7; background-color: #07B500; text-align: center;">
			<i class="fa fa-check" style="font-size: 80px; color:white; margin-top:20px;"></i>
		</div>
		<div (click)="toggleSupervisor()"
			style=" margin-left:22px; margin-top:20px; width:130px; height:130px; border:5px solid #FFEBA7; background-color: #EB9A00; text-align: center;">
			<i class="fa fa-engine-warning" style="font-size: 80px; color:white; margin-top:20px;"></i>
		</div>
		<div (click)="toggleMode()"
			style=" margin-left:22px; margin-top:20px; width:395px; height:130px; border:5px solid #B5A7FF; background-color: #7F00EB; text-align: center;">
			<i class="fa fa-tools" style="font-size: 80px; color:white; margin-top:20px;"></i>
		</div>
		<div (click)="reloadPage()"
			style=" margin-left:22px; margin-top:20px; width:130px; height:130px; border:5px solid #A7DDFF; background-color: #0076EB; text-align: center;">
			<i class="fa fa-redo-alt" style="font-size: 80px; color:white; margin-top:20px;"></i>
		</div>
	</div>
	<div *ngIf="currentStatus==10" id="statusBar" class="status-active" style="margin-top: 25px;"></div>
	<div *ngIf="currentStatus==20" id="statusBar" class="status-idle" style="margin-top: 19px;"></div>
	<div *ngIf="currentStatus>29" id="statusBar" class="status-maintenance" style="margin-top: 19px;"></div>

	<!-- <img src="../../../../assets/Logo.png" width="200" style="margin-top:25px; margin-left:140px; margin-bottom:25px;">
<input class="input-override" type="password" name="password" (keyup.enter)="submitData()"
	[(ngModel)]="fobScannedEntity.fobId" placeholder="Fob ID" autocomplete="off"
	style="margin-left:25px; margin-right:25px;">
<button class="btn-override" (click)="submitData()" style="margin-left:25px; margin-top:25px;">
	Submit Details
</button> -->

</div>