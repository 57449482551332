import { Component, ViewEncapsulation } from "@angular/core";
import { Router } from "@angular/router";
import { environment } from "@environments/environment";
import { AdminMenu } from "@interfaces/adminMenu.interface";
import { AdminMenuItem } from "@interfaces/adminMenuItem.interface";
import { LoginDetails } from "@interfaces/loginDetails.interface";
import { UserProfile } from "@interfaces/userProfile.interface";
import { AppSettingsService } from "@services/appSettings.service";
import { AuthService } from "@services/auth.service";
import { BannerService } from "@services/banner.service";
import { MenuService } from "@services/menu.service";
import { MenuItemService } from "@services/menuItem.service";
import notify from "devextreme/ui/notify";

@Component({
	encapsulation: ViewEncapsulation.None,
	moduleId: module.id,
	selector: "adminRoute",
	styleUrls: ["adminRoute.css"],
	templateUrl: "adminRoute.html"
})
export class AdminRouteComponent {
	bannerText = "";
	companyName = "";
	copyright = "";
	displayBanner = false;
	isDrawerOpen = true;
	loggedIn = false;
	loginButtonOptions = { onClick: () => this.login(), text: "Login", type: "success", useSubmitBehavior: true };
	loginDetails: LoginDetails = new LoginDetails();
	menuItems: AdminMenuItem[] = [];
	showLoginForm = false;
	staging= false;
	subMenus: AdminMenu[] = [];
	systemName = "BPL Flow";
	userMenuItems: AdminMenuItem[] = [
		{ icon: "fal fa-user-cog", route: "user-settings", sortOrder: 998, text: "Settings" },
		{ icon: "fal fa-sign-out", route: "logout", sortOrder: 999, text: "Logout" }
	];
	userProfile: UserProfile = new UserProfile();
	validationErrors = {login: "", password: ""};

	constructor(private router: Router, private appSettingsService: AppSettingsService, private authService: AuthService, private bannerService: BannerService, private menuService: MenuService, private menuItemService: MenuItemService) {
		this.companyName = this.getEnvironmentProperty("companyLongName");
		this.copyright = "© Copyright " + this.companyName + " " + new Date().getFullYear();
		if(!environment.build.production)
		{
			this.staging = true;
		}
		if (router.url.includes("complete-account") || router.url.includes("forgotPassword") || router.url.includes("clocking") || router.url.includes("operator") || router.url.includes("resetPassword")) {
			this.loggedIn = false;
			this.showLoginForm = false;
		} else {
			this.authService.authCheck.subscribe((value) => {
				if (value === true) {
					this.getMenuItems();
					this.getBannerText();
					this.loggedIn = true;
				} else {
					this.loggedIn = false;
					this.showLoginForm = true;
					this.userProfile = new UserProfile();
					notify("Your Session has expired, please login.", "error", 5000);
				}
			});
		}
	}

	// eslint-disable-next-line @typescript-eslint/no-empty-function
	forgottenPassword() {}

	getBannerText() {
		this.bannerService.getBanner()
			.subscribe(
				(res: any) => {
					const bannerDetails = res.response;
					this.displayBanner = bannerDetails.display;
					this.bannerText = bannerDetails.text;
				},
				(err) => console.log(err)
			);
	}

	getEnvironmentProperty(property: string) {
		// @ts-ignore // Required to get around having to iterate through the object
		return environment["build"][property];
	}

	getMenuItems() {
		this.menuItemService.getUserMenuItems("main")
			.subscribe(
				(res: any) => {
					this.menuItems = res.response;
					this.getSubMenus();
				},
				(err) => console.log(err)
			);
	}

	getSubMenus() {
		this.menuService.getUserMainMenu()
			.subscribe(
				(res: any) => {
					this.subMenus = res.response;
					this.subMenus.forEach((subMenu) => {
						this.menuItems.push({ icon: subMenu.icon, route: `/menu/${subMenu.slug}`, sortOrder: 999, text: subMenu.name });
					});
				},
				(err) => console.log(err)
			);
	}
	
	isActiveRoute(route: string) {
		return location.href.endsWith(`${environment.build.adminRoute}${route}`);
	}

	login() {
		this.validationErrors = {login: "", password: ""};

		this.authService.loginAdmin(this.loginDetails)
			.subscribe(
				() => {
					if (this.menuItems.length < 1 || this.menuItems == null)
					{
						this.getMenuItems();
					}
					this.loggedIn = true;
					this.getBannerText();
				},
				(err) => this.validationErrors.login = err
			);
		return true;
	}

	// eslint-disable-next-line @typescript-eslint/no-empty-function
	logout() {
		this.loggedIn = false;
		this.showLoginForm = true;
		this.loginDetails = new LoginDetails();
		this.appSettingsService.set("accessToken", null);
		this.appSettingsService.set("accessTokenExpiryTimeMilliseconds", 0);
		this.router.navigate(["admin"]);
	}

	navigate(menuItem: AdminMenuItem) {
		if (menuItem.route !== "logout") this.router.navigate([`admin/${menuItem.route}`]);
		else this.logout();
	}

	navigateUrl(url: string) {
		this.router.navigate([url]);
	}

	toggleSideDrawer() {
		this.isDrawerOpen = !this.isDrawerOpen;
	}

	windowWidthCheck() {
		return window.innerWidth <= 1366;
	}
}