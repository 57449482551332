import { Component } from "@angular/core";
import { ActivatedRoute,Router } from "@angular/router";
import { Department } from "@enums/department";
import { MachineEventType } from "@enums/machineEventType.enum";
import { ProductionStatus } from "@enums/productionStatus.enum";
import { GetPropertiesFromEnum } from "@helpers/getPropertiesFromEnum";
import { AdminHeaderButton } from "@interfaces/adminHeaderButton.interface";
import { InterfaceDevice } from "@interfaces/interfaceDevice.interface";
import { Machine } from "@interfaces/machine.interface";
import { MachineDocumentFileLineData } from "@interfaces/machineDocumentFileLineData";
import { MachineEvent } from "@interfaces/machineEvent.interface";
import { Site } from "@interfaces/site.interface";
import { FileService } from "@services/file.service";
import { InterfaceDeviceService } from "@services/interfaceDevice.service";
import { MachineService } from "@services/machine.service";
import { MachineEventService } from "@services/machineEvent.service";
import { OpsActionAuditService } from "@services/opsActionAudit.service";
import { SiteService } from "@services/site.service";
import { Guid } from "guid-typescript";
import notify from "devextreme/ui/notify";

@Component({
	moduleId: module.id,
	selector: "machines-id",
	styleUrls: ["machines-id.css"],
	templateUrl: "machines-id.html"
})
export class MachinesIdAdminComponent {
	createEnabled = true;
	departments: any;
	documents: MachineDocumentFileLineData[] = [];
	editEnabled = true;
	events: MachineEvent[] = [];
	headerPrimaryButtons: AdminHeaderButton[] = [];
	headerSecondaryButtons: AdminHeaderButton[] = [];
	interfaceDevices: InterfaceDevice[] = [];
	item: Machine = new Machine();
	itemType = "Machine";
	machineEventTypes: any;
	mode = "";
	opsActionAudits: any;
	popupAddButtonOptions: any = { onClick: () => this.addNewEvent(), text: "Add Event", visible: true };
	popupCloseButtonOptions: any = { onClick: () => this.closePopup(), text: "Close" };
	popupDocument: MachineDocumentFileLineData = new MachineDocumentFileLineData();
	popupEvent: MachineEvent = new MachineEvent();
	popupTitle = "Add New Event";
	popupVisible = false;
	productionStatuses: any;
	readOnly = true;
	returnUrl: string = location.href.split("/")[3] + "/" + location.href.split("/")[4];
	sites: Site[] = [];
	statuses: any;
	title: string = "View " + this.itemType;

	constructor(private router: Router, private route: ActivatedRoute, private fileService: FileService, private interfaceDeviceService: InterfaceDeviceService, private itemService: MachineService, private machineEventService: MachineEventService, private opsActionAuditService: OpsActionAuditService, private siteService: SiteService) {
		this.buildButtons("view");
		this.departments = GetPropertiesFromEnum(Department);
		this.machineEventTypes = GetPropertiesFromEnum(MachineEventType);
		this.productionStatuses = GetPropertiesFromEnum(ProductionStatus);
		this.getInterfaceDevices();
		this.getSites();
		this.route.params
			.subscribe((params) => {
				if (params.id != "create") {
					if (params.id.split("_").length > 1) {
						this.getItem(params.id.split("_")[0]);
						this.edit();
					} else {
						this.getItem(params.id);
					}
				} else {
					this.create();
				}
			});
		
		this.deleteDocumentLink = this.deleteDocumentLink.bind(this);
		this.deleteEvent = this.deleteEvent.bind(this);
		this.downloadFileLink = this.downloadFileLink.bind(this);
		this.editEvent = this.editEvent.bind(this);
		this.uploadDocument = this.uploadDocument.bind(this);
	}

	addNewDocument() {
		if (this.popupDocument.description === "") {
			alert("Please enter a description");
			return;
		}
		this.documents.push(this.popupDocument);
		this.popupDocument = new MachineDocumentFileLineData();
		this.closePopup();
	}

	addNewDocumentPopup() {
		this.popupDocument = new MachineDocumentFileLineData();
		this.popupTitle = "Add New Document";
		this.popupVisible = true;
		this.popupAddButtonOptions = { onClick: () => this.addNewDocument(), text: "Add Document" };
	}

	addNewEvent() {
		this.popupEvent.machineId = this.item.id;
		this.machineEventService.createSingle(this.popupEvent)
			.subscribe(
				() => {
					this.popupVisible = false;
					this.popupEvent = new MachineEvent();
					this.getEvents();
					notify("Successfully Added Event ", "success", 5000);
				},
				(err: any) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				}
			);
	}

	addNewEventPopup() {
		this.popupEvent = new MachineEvent();
		this.popupTitle = "Add New Event";
		this.popupAddButtonOptions = { onClick: () => this.addNewEvent(), text: "Add Event", visible: true };
		this.popupVisible = true;
	}

	buildButtons(mode: string) {
		this.mode = mode;
		if (mode == "edit" && this.editEnabled) {
			this.headerPrimaryButtons = [
				{ method: "updateItem", text: "Save Changes" },
				{ method: "cancelEditing", text: "View Mode" },
				{ method: "close", text: "Close" }
			];
			this.headerSecondaryButtons = [{ method: "addNewDocumentPopup", text: "Add Document" }];
		} else if (mode == "create" && this.createEnabled) {
			this.headerPrimaryButtons = [
				{ method: "createItem", text: "Save" },
				{ method: "close", text: "Close" }
			];
			this.headerSecondaryButtons = [{ method: "addNewDocumentPopup", text: "Add Document" }];
		} else if (mode == "view") {
			this.headerPrimaryButtons = [
				{ method: "edit", text: "Edit" },
				{ method: "close", text: "Close" }
			];
			this.headerSecondaryButtons = [
				{ method: "viewPFMEAs", text: "View PFMEAs" },				
				{ method: "addNewEventPopup", text: "Add Event" }
			];
		}
	}

	cancelEditing() {
		if (confirm("Are you sure you wish to switch modes? Unsaved changes will be discarded.") == false) {
			return;
		}
		this.readOnly = true;
		this.buildButtons("view");
		this.title = "View " + this.itemType;
	}

	close() {
		this.router.navigate([`/${this.returnUrl}/`]);
	}

	closePopup() {
		this.popupVisible = false;
	}

	create() {
		this.readOnly = false;
		this.buildButtons("create");
		this.title = "Create New " + this.itemType;
	}

	createItem() {
		this.item.documents = JSON.stringify(this.documents);
		this.itemService.createSingle(this.item)
			.subscribe(
				() => {
					notify("Successfully Updated " + this.itemType, "success", 5000);
					this.item = new Machine();
					this.close();
				},
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				}
			);
	}

	deleteDocumentLink(e: any) {
		const index = this.documents.findIndex(d => d.lookupId === e.row.data.lookupId);
		this.documents.splice(index, 1);

		return false;
	}

	deleteEvent(e: any) {
		if (confirm("Are you sure you wish to delete this event?")) {
			this.machineEventService.deleteSingleById(e.row.data.id)
				.subscribe(
					() => {
						notify("Event Deleted", "success", 5000);
						this.getEvents();
					},
					(err: any) => console.log(err)
				);
		}
	}

	downloadFileLink(e: any) {
		const lookupId = Guid.parse(e.row.data.lookupId);
		this.fileService.downloadFile(lookupId).subscribe(
			(response: any) =>{
				const dataType = response.type;
				const binaryData = [];
				binaryData.push(response);
				const downloadLink = document.createElement("a");
				downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
				downloadLink.setAttribute("download", e.row.data.fileName);
				document.body.appendChild(downloadLink);
				downloadLink.click();
			}
		);
		return false;
	}

	edit() {
		this.readOnly = false;
		this.buildButtons("edit");
		this.title = "Edit " + this.itemType;
		notify("You are now editing...", "warning", 5000);
	}
	
	editEvent(e: any) {
		const eventToEdit = this.events.find((f)=> f.id == e.row.data.id);
		if (eventToEdit !== null && eventToEdit !== undefined) {
			this.popupEvent = eventToEdit;
		} else {
			return;
		}
		this.popupTitle = "Edit Event";
		this.popupAddButtonOptions = { onClick: () => this.updateEvent(), text: "Edit Event", visible: true };
		this.popupVisible = true;
	}

	getEvents() {
		this.machineEventService.getByMachineId(this.item.id)
			.subscribe(
				(res: any) => this.events = res.response,
				(err) => {
					console.log(err);
					notify("Something Went Wrong!", "Error", 5000);
				}
			);
	}

	getInterfaceDevices() {
		this.interfaceDeviceService.getAll()
			.subscribe(
				(res: any) => this.interfaceDevices = res.response.items,
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				},
			);
	}

	getItem(itemId: number) {
		this.itemService.getSingleById(itemId)
			.subscribe(
				(res: any) => {
					this.item = res.response;
					this.documents = JSON.parse(this.item.documents);
					this.getEvents();
					this.getOpsActionAudits(itemId);
				},
				(err) => {console.log(err); notify("Something Went Wrong!", "Error", 5000);}
			);
	}

	getOpsActionAudits(workOrderId: number)	{
		this.opsActionAuditService.getByMachineId(workOrderId)
			.subscribe(
				(res: any) => {
					this.opsActionAudits = res.response;
				},
				(err) => console.log(err)
			);
	}

	getSites() {		
		this.siteService.getAll()
			.subscribe(
				(res: any) => this.sites = res.response.items,
				(err) => console.log(err)
			);
	}

	headerButtonClick(method: any) {
		// @ts-ignore // Required to be able to call the method directly from the variable
		if (this[method]) this[method]();
	}

	updateEvent() {
		this.machineEventService.updateSingleById(this.popupEvent.id, this.popupEvent)
			.subscribe(
				() => {
					this.popupVisible = false;
					this.popupEvent = new MachineEvent();
					this.getEvents();
					notify("Successfully Edited Event ", "success", 5000);
				},
				(err: any) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				} 
			);
	}

	updateItem() {
		this.item.documents = JSON.stringify(this.documents);
		this.itemService.updateSingleById(this.item.id,this.item)
			.subscribe(
				() => {
					notify("Successfully Updated " + this.itemType, "success", 5000);
				},
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				}
			);
	}

	uploadDocument(file: File) {
		const containerName = "machinefiles";
		const uploadData = new FormData();
		uploadData.append("containerName", containerName);
		uploadData.append("files", file);
		// Add Mime Type key when mime types are decided upon...
		// uploadData.append("mimeTypeKey", "CADFileMimeTypes");
		this.fileService.uploadFile(uploadData)
			.subscribe(
				(res: any) => {
					this.popupDocument.lookupId = res.response[0].lookupId;
					this.popupDocument.fileName = res.response[0].name;
				},
				(err) => {
					console.log(err);
					notify("Document Upload Failed - Please ensure you are uploading a valid file", "error", 5000);
				}
			);
	}

	viewPFMEAs() {		
		this.router.navigate([`admin/machines/${this.item.id}/pfmeas`]);
	}
}