import { Component } from "@angular/core";
import { Router } from "@angular/router";
import notify from "devextreme/ui/notify";
import { AdminHeaderButton } from "@interfaces/adminHeaderButton.interface";
import { Client } from "@interfaces/client.interface";
import { ClientLocation } from "@interfaces/clientLocation.interface";
import { InvoiceGridRow } from "@interfaces/invoiceGridRow.interface";
import { LogExport } from "@interfaces/logExport.interface";
import { ClientService } from "@services/client.service";
import { ClientLocationService } from "@services/clientLocation.service";
import { ExportLogService } from "@services/exportLog.service";
import { InvoiceService } from "@services/invoice.service";

@Component({
	moduleId: module.id,
	selector: "invoices",
	styleUrls: ["invoices.css"],
	templateUrl: "invoices.html"
})
export class InvoicesAdminComponent {
	clientLocations: ClientLocation[] = [];
	clients: Client[] = [];
	headerPrimaryButtons: AdminHeaderButton[] = [];
	headerSecondaryButtons: AdminHeaderButton[] = [];
	itemType = "Invoice"
	itemUrl: string = location.href.split("/")[3] + "/" + location.href.split("/")[4];
	items: InvoiceGridRow[] = [];
	title: string;
	
	constructor(private router: Router, private itemService: InvoiceService, private clientService: ClientService, private clientLocationService: ClientLocationService, private exportLogService: ExportLogService) {
		this.title = "Invoices";
		this.deleteRow = this.deleteRow.bind(this);
		this.generatePreviewPDF = this.generatePreviewPDF.bind(this);
		this.viewRow = this.viewRow.bind(this);
		this.viewSalesOrder = this.viewSalesOrder.bind(this);
		
		this.getItems();
		this.getClients();
		this.getClientLocations();
	}

	deleteRow(e: any) {
		const result = confirm("Are you sure you want to delete this invoice?");
		if(result)
		{
			this.itemService.deleteSingleById(e.row.data.id)
				.subscribe(
					() => {
						notify("Invoice deleted!", "success", 5000);
						this.getItems();
					},
					(err) => {
						console.log(err);
						notify("Something went wrong!", "error", 5000);
					},
				);
		}
	}

	generatePreviewPDF(e: any) {
		this.itemService.generateAndPreviewPdf(e.row.data.id).subscribe(
			(response: any) =>{
				const dataType = response.type;
				const binaryData = [];
				binaryData.push(response);
				const downloadLink = document.createElement("a");
				downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
				downloadLink.setAttribute("download", "INV" + e.row.data.id + "-" + this.clients.find(x => x.id == e.row.data.clientId)?.name +  ".pdf");
				document.body.appendChild(downloadLink);
				downloadLink.click();
			}
		);
	}
	
	getClientLocations() {
		this.clientLocationService.getAll()
			.subscribe(
				(res: any) =>  {
					this.clientLocations = res.response.items;
				},
				(err) => console.log(err)
			);
	}
	
	getClients() {
		this.clientService.getAll()
			.subscribe(
				(res: any) =>  {
					this.clients = res.response.items;
				},
				(err) => console.log(err)
			);
	}

	getItems() {
		this.itemService.getGrid()
			.subscribe(
				(res: any) => this.items = res.response,
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				},
			);
	}

	headerButtonClick(method: any) {
		// @ts-ignore // Required to be able to call the method directly from the variable
		if (this[method]) this[method]();
	}

	logExport() {
		const logExport = new LogExport();
		logExport.gridName = "Invoices";
		this.exportLogService.logExport(logExport)
			.subscribe(
				() => console.log("Export Logged"),
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
					return false;
				},
			);
	}

	viewRow(e: any) {
		this.router.navigate([`${this.itemUrl}/${e.row.data.lookupId}`]);
	}

	viewSalesOrder(e: any) {
		this.router.navigate([`admin/salesOrders/${e.row.data.salesOrderLookupId}`]);
	}
}