<admin-header [primaryButtons]="headerPrimaryButtons" [title]="title" (eventFired)="headerButtonClick($event)"></admin-header>
<div class="form-container">
	<dx-form class="m-3" [(formData)]="item" [showColonAfterLabel]="true" labelLocation="top" [readOnly]="readOnly" (onFieldDataChanged)="formDataChanged($event)">
		<dxi-item itemType="group" caption="Details" [colCount]="8">
			<dxi-item dataField="potentialFailure" [colSpan]="8"></dxi-item>
			<dxi-item dataField="potentialCause" [colSpan]="8"></dxi-item>
			<dxi-item dataField="potentialEffect" [colSpan]="8"></dxi-item>
			<dxi-item [colSpan]="2" dataField="severity" editorType="dxSelectBox" [editorOptions]="{ items: allowedNumbers, searchEnabled: false }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>	
			<dxi-item [colSpan]="2" dataField="occurance" editorType="dxSelectBox" [editorOptions]="{ items: allowedNumbers, searchEnabled: false }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>	
			<dxi-item [colSpan]="2" dataField="detection" editorType="dxSelectBox" [editorOptions]="{ items: allowedNumbers, searchEnabled: false }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>	
			<dxi-item [colSpan]="2" dataField="rpn" [editorOptions]="{ readOnly: true }" [label]="{ text: 'RPN' }"></dxi-item>	
			<dxi-item dataField="charge" [colSpan]="8"></dxi-item>
			<dxi-item dataField="recommendedAction" [colSpan]="8"></dxi-item>
			<dxi-item dataField="completionDate" [colSpan]="8" editorType="dxDateBox" [editorOptions]="{ displayFormat: 'dd/MM/yyyy hh:mm:ss', showClearButton: true, type: 'datetime' }"></dxi-item>
		</dxi-item>
		<dxi-item itemType="group" caption="CPC">
			<dx-data-grid #cpcGridContainer class="data-grid" [dataSource]="cpcDetails" [remoteOperations]="false" [allowColumnReordering]="false" [rowAlternationEnabled]="true" [showBorders]="true">
				<dxo-paging [pageSize]="100"></dxo-paging>
				<dxo-state-storing [enabled]="true" type="localStorage" storageKey="pfmeaCPCDetails"></dxo-state-storing>
				<dxo-pager [visible]="false" [showPageSizeSelector]="false" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
				<dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"></dxo-search-panel>
				<dxo-group-panel [visible]="false"></dxo-group-panel>
				<dxo-grouping [autoExpandAll]="false"></dxo-grouping>
				<dxo-export [enabled]="false"></dxo-export>
				<dxo-editing mode="row" [allowAdding]="true" [allowDeleting]="true" [allowUpdating]="true" [confirmDelete]="false" [useIcons]="true" newRowPosition="last" [changes]="changes" [(editRowKey)]="editRowKey"></dxo-editing>
				<dxo-toolbar>
					<dxi-item name="addRowButton" showText="always"></dxi-item>
				</dxo-toolbar>
				<dxi-column dataField="detection" dataType="text" alignment="left"></dxi-column>
				<dxi-column dataField="prevention" dataType="text" alignment="left"></dxi-column>
				<dxi-column type="buttons">
					<dxi-button name="edit"></dxi-button>
					<dxi-button name="delete"></dxi-button>
					<dxi-button name="save"></dxi-button>
					<dxi-button name="cancel"></dxi-button>
				</dxi-column>
			</dx-data-grid>
		</dxi-item>
		<dxi-item itemType="group" caption="Actions Taken">
			<dx-data-grid #actionsTakenGridContainer class="data-grid" [dataSource]="actionsTaken" [remoteOperations]="false" [allowColumnReordering]="false" (onInitNewRow)="onInitNewActionTakenRow($event)" [rowAlternationEnabled]="true" [showBorders]="true">
				<dxo-paging [pageSize]="100"></dxo-paging>
				<dxo-state-storing [enabled]="true" type="localStorage" storageKey="pfmeaActionsTaken"></dxo-state-storing>
				<dxo-pager [visible]="false" [showPageSizeSelector]="false" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
				<dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"></dxo-search-panel>
				<dxo-group-panel [visible]="false"></dxo-group-panel>
				<dxo-grouping [autoExpandAll]="false"></dxo-grouping>
				<dxo-export [enabled]="false"></dxo-export>
				<dxo-editing mode="row" [allowAdding]="true" [allowDeleting]="true" [allowUpdating]="true" [confirmDelete]="false" [useIcons]="true" newRowPosition="last" [changes]="changes" [(editRowKey)]="editRowKey"></dxo-editing>
				<dxo-toolbar>
					<dxi-item name="addRowButton" showText="always"></dxi-item>
				</dxo-toolbar>
				<dxi-column dataField="actionTaken" dataType="text" alignment="left"></dxi-column>
				<dxi-column dataField="severity" dataType="number" alignment="left"><dxo-lookup [dataSource]="allowedNumbers"></dxo-lookup></dxi-column>
				<dxi-column dataField="occurance" dataType="number" alignment="left"><dxo-lookup [dataSource]="allowedNumbers"></dxo-lookup></dxi-column>
				<dxi-column dataField="detection" dataType="number" alignment="left"><dxo-lookup [dataSource]="allowedNumbers"></dxo-lookup></dxi-column>
				<dxi-column dataField="rpn" dataType="number" alignment="left" [allowEditing]="false" caption="RPN"></dxi-column>
				<dxi-column type="buttons">
					<dxi-button name="edit"></dxi-button>
					<dxi-button name="delete"></dxi-button>
					<dxi-button name="save" [onClick]="saveActionsTakenChanges"></dxi-button>
					<dxi-button name="cancel"></dxi-button>
				</dxi-column>
			</dx-data-grid>
		</dxi-item>
	</dx-form>
</div>