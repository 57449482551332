/* eslint-disable linebreak-style */
import { Component, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ProductionStatus } from "@enums/productionStatus.enum";
import { environment } from "@environments/environment";
import { Machine } from "@interfaces/machine.interface";
import { Part } from "@interfaces/part.interface";
import { WorkOrderStage } from "@interfaces/workOrderStage.interface";
import { AppSettingsService } from "@services/appSettings.service";
import { MachineService } from "@services/machine.service";
import { WorkOrderStageService } from "@services/workOrderStage.service";
import notify from "devextreme/ui/notify";

@Component({
	encapsulation: ViewEncapsulation.None,
	moduleId: module.id,
	selector: "operator",
	styleUrls: ["operator.css"],
	templateUrl: "operator.html"
})
export class OperatorComponent {
	availableStages: WorkOrderStage[] = [];
	clientName = "";
	companyName = "";
	copyright = "";
	currentStatus = ProductionStatus.Idle;
	machine = new Machine();
	machineId = 0;
	operatorName = "";
	part = new Part();
	partImage = "";
	quantityComplete = 0;
	runningStages: WorkOrderStage[] = [];
	showComplete = false;
	showMode = false;
	showPopup = false;
	showSupervisor = false;
	siteId = 0;
	stageNumber = "";

	constructor(private route: ActivatedRoute, private workOrderStageService: WorkOrderStageService, private machineService: MachineService, private appSettingsService: AppSettingsService) {
		this.companyName = this.getEnvironmentProperty("companyLongName");
		this.copyright = "© Copyright " + this.companyName + " " + new Date().getFullYear();
		this.route.params
			.subscribe((params) => {
				this.siteId = Number.parseInt(params.id);
				this.getData();
			});
	}

	changeMode(status: ProductionStatus) {
		this.submitProductionMode(status);
		this.toggleMode();
	}

	getData() {
		this.machineService.opsPlannerByDeviceId(this.siteId)
			.subscribe(
				(res: any) => {
					this.machine = res.response[0];
					let profileId = 0;
					this.currentStatus = res.response[0].productionStatus;
					this.availableStages = res.response[0].availableStages;
					this.runningStages = res.response[0].runningStages;
					this.partImage = res.response[0].availableStages[0].partImage;
					this.clientName = res.response[0].availableStages[0].clientName;
					this.part = res.response[0].availableStages[0];
					this.stageNumber = res.response[0].availableStages[0].operationNumber;
					this.machineId = res.response[0].availableStages[0].machineId;
					profileId = res.response[0].availableStages[0].assignedUserProfileId;
					if (this.runningStages.length > 0) {
						this.availableStages = this.runningStages;
						this.partImage = res.response[0].runningStages[0].partImage;
						this.part = res.response[0].runningStages[0];
						this.clientName = res.response[0].runningStages[0].clientName;
						this.operatorName = res.response[0].runningStages[0].assignedUserProfileFullName;
						this.stageNumber = res.response[0].runningStages[0].operationNumber;
						this.machineId = res.response[0].runningStages[0].machineId;
						profileId = res.response[0].runningStages[0].assignedUserProfileId;
					}
					this.appSettingsService.set("profileId", profileId);
				},
				(err) => {
					console.log(err);
					notify("Something Went Wrong!", "Error", 5000);
				}
			);
	}

	getEnvironmentProperty(property: string) {
		// @ts-ignore // Required to get around having to iterate through the object
		return environment["build"][property];
	}

	reloadPage() {
		window.location.reload();
	}

	submitCompleted() {
		this.workOrderStageService.completedQuantityApiKey(this.availableStages[0].id, this.quantityComplete)
			.subscribe(
				() => {
					notify("Part Complete Submitted Successfully!", "success", 5000);
					this.reloadPage();
				},
				(err) => {
					notify("Something went wrong!", "error", 5000);
					console.log(err);
				}
			);
	}

	submitProductionMode(prodStatus: ProductionStatus) {
		this.machineService.AppUpdateProductionStatus(this.machineId, prodStatus)
			.subscribe(
				() => {
					notify("Production Mode Changed Successfully!", "success", 5000);
					this.reloadPage();
				},
				(err) => {
					notify("Something went wrong!", "error", 5000);
					console.log(err);
				}
			);
	}

	submitSupervisorRequest() {
		this.machineService.requestSupervisorForStage(this.availableStages[0].id)
			.subscribe(
				() => {
					notify("Request Submitted Successfully!", "success", 5000);
				},
				(err) => {
					notify("Something went wrong!", "error", 5000);
					console.log(err);
				}
			);
	}

	/* Toggles */
	toggleComplete() {
		this.showComplete = !this.showComplete;
		this.showPopup = !this.showPopup;
	}

	toggleMode() {
		this.showMode = !this.showMode;
		this.showPopup = !this.showPopup;
	}

	toggleSupervisor() {
		if (!this.showSupervisor) {
			this.submitSupervisorRequest();
		}
		this.showSupervisor = !this.showSupervisor;
		this.showPopup = !this.showPopup;
	}

	windowWidthCheck() {
		return window.innerWidth >= 768;
	}

}